
/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="../../../Resolution.ts" />
/// <reference path="../../../Options.ts" />
/// <reference path="./GenericHeader.ts" />
/// <reference path="../../ArrayExtensions.ts" />


namespace spacedesk.Model.Protocol.Packet.Header {

    export class IdentificationHeader extends GenericHeader {

        public clientType = ProtocolClientType.WebBrowser;
        public colorType = ProtocolColorType.RGBA32;

        public compression: number;
        public subsampling: number;
        public quality: number;

        public resolution: Resolution = null;
        public resolutionCustom: Resolution = null;

        public frameRate : number;

        public GetBytes(): Uint8Array {

            let header = new Uint8Array(ProtocolDefinition.headerLength);

            header.assignInteger32(0, this.identification);
            header.assignInteger32(4, this.countByteDataFollowingHeader);
            header.assignInteger32(8, ProtocolDefinition.versionNumberMajor);
            header.assignInteger32(12, ProtocolDefinition.versionNumberMinor);
            header.assignInteger32(16, this.clientType);

            if (this.quality <= 0) {
                this.quality = 1;
            }

            header.assignInteger32(20, 0);
            header.assignInteger32(24, 3);
            header.assignInteger32(24, this.compression);
            header.assignInteger32(32, this.quality);
            
            header.assignInteger16(44, this.frameRate);
            header.assignInteger16(46, 0);

            // Standard Resolution
            header.assignInteger32(52, this.resolution.width);
            header.assignInteger32(88, this.resolution.height);

            if (this.resolutionCustom != null) {
                // Set Resolution Count;
                header.assignInteger32(48, 2);

                // Set Custom Resolution
                header.assignInteger32(56, this.resolutionCustom.width);
                header.assignInteger32(92, this.resolutionCustom.height);

            }
            else {
                // Set Resolution Count				
                header.assignInteger32(48, 1);
                header.assignInteger32(56, 0);
                header.assignInteger32(92, 0);
            }

            // Set default Resolutions X
            header.assignInteger32(60, 0);
            header.assignInteger32(64, 0);
            header.assignInteger32(68, 0);
            header.assignInteger32(72, 0);
            header.assignInteger32(76, 0);
            header.assignInteger32(80, 0);
            header.assignInteger32(84, 0);

            // Set default Resolution Y
            header.assignInteger32(96, 0);
            header.assignInteger32(100, 0);
            header.assignInteger32(104, 0);
            header.assignInteger32(108, 0);
            header.assignInteger32(112, 0);
            header.assignInteger32(116, 0);
            header.assignInteger32(120, 0);

            // Set Licensing
            header.assignInteger32(124, 0);

            return header;
        }

        public LoadFromBuffer(buffer: Uint8Array) {
            return;
        }

        constructor(settings: Options, protocolType?: ProtocolClientType) {

            super();

            if (protocolType != null) {
                this.clientType = protocolType;
            }
            this.identification = ProtocolHeaderType.Identification;

            this.compression = settings.compression;
            this.subsampling = settings.subsampling;
            this.quality = settings.quality;
            this.frameRate = settings.frameRate;

            if (settings.useClientResolution) {
                this.resolution = Resolution.GetScreenResolution();

                // Use custom resolution?
                if (settings.useCustomResolution && settings.resolutionCustom != null) {
                    this.resolutionCustom = settings.resolutionCustom;
                }
            }
            else if (settings.useCustomResolution) {
                this.resolution = settings.resolutionCustom;
            }
            else {
                // Fallback all unchecked useClientResolution
                this.resolution = Resolution.GetScreenResolution();
            }


        }
    }
}