/// <reference path="../../Model/Utils.ts" />
/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../../Model/Options.ts" />
/// <reference path="../../Controller/ViewController.ts" />


namespace spacedesk.Controller.UI {

    export class PointerLockController {


        public static get pointerLockActivated(): boolean {
            return (document.pointerLockElement || (document as any).mozPointerLockElement || (document as any).webkitPointerLockElement) != null;
        }

        public static get pointerLockEnabled(): boolean {
            return Model.Options.currentOptions.kvmControl &&
                !ViewController.touchDevice &&
                ("pointerLockElement" in document || "mozPointerLockElement" in document || "webkitPointerLockElement" in document);
        }

        public static requestPointerLock() {
            // Pointer Lock
            if (this.pointerLockEnabled) {
                ViewController.Canvas.requestPointerLock = ViewController.Canvas.requestPointerLock ||
                    (ViewController.Canvas as any).mozRequestPointerLock ||
                    (ViewController.Canvas as any).webkitRequestPointerLock;
                // Ask the browser to lock the pointer
                ViewController.Canvas.requestPointerLock();
                console.log("Pointer requested in PointerLockController!");
            }
        }

        public static exitPointerLock() {
            // Pointer Lock            
            if (this.pointerLockEnabled) {

                // Ask the browser to release the pointer
                document.exitPointerLock = document.exitPointerLock ||
                    (document as any).mozExitPointerLock ||
                    (document as any).webkitExitPointerLock;
                document.exitPointerLock();
            }
        }


    }
}