
namespace spacedesk {

    export class Utils {

        public static browser = $.pgwBrowser();

        public static get isIE(): boolean {
            return Utils.browser.browser.group === "Explorer" || Utils.browser.userAgent.indexOf("Edge") > 0;
        }

        public static get isEdge(): boolean {
            return Utils.browser.userAgent.indexOf("Edge") > 0;
        }

        public static get isAndroid(): boolean {
            return Utils.browser.os.group === "Android";
        }
        public static get iOSDevice(): boolean {
            return Utils.browser.os.group === "iOS";
        }

        public static get isDesktopDevice(): boolean {

            switch (Utils.browser.os.group) {
                case "Windows":
                case "Chrome OS":
                case "Mac OS":
                case "Linux":
                    return true;
                default:
                    return false;
            }
        }

        public static get isRetinaDisplay(): boolean {
            const mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

            if (window.devicePixelRatio > 1) {
                return true;
            }

            if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
                return true;
            }

            return false;
        }
    }

}