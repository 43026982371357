
namespace spacedesk.Model.Protocol.Packet.Payloads {

    interface IPacketPayload {
        data: Uint8Array;
    }

    export class GenericPayload implements IPacketPayload {

        public data: Uint8Array;

        public constructor(buffer?: Uint8Array) {

            if (buffer != null) {
                this.data = buffer;
            }

        }
    }


}