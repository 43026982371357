
namespace spacedesk.Model {

    export class Rect {
        public posX: number;
        public posY: number;

        public posX2: number;
        public posY2: number;

        public width: number = this.posX2 - this.posX;
        public height: number = this.posY2 - this.posY;

        constructor(posX = 0, posY = 0, posX2 = 0, posY2 = 0) {
                        
            this.posX = posX;
            this.posY = posY;

            this.posX2 = posX2;
            this.posY2 = posY2;

        }
    }
}