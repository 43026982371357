/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="./Header/HeaderFactory.ts" />
/// <reference path="./Header/GenericHeader.ts" />
/// <reference path="./Payloads/PayloadFactory.ts" />
/// <reference path="./Payloads/GenericPayload.ts" />

namespace spacedesk.Model.Protocol.Packet {

    interface IPacket {
        header: Header.GenericHeader;
        payload: Payloads.GenericPayload;
    }

    export class SpacedeskPacket implements IPacket {

        public header: Header.GenericHeader;

        public payload: Payloads.GenericPayload;

        public constructor(buffer?: ArrayBuffer) {

            if (buffer == null) {
                return;
            }

            if (buffer.byteLength < 128) {
                Debug.error("Received Packet is to small.");
                return;
            }

            this.header = Header.HeaderFactory.GetHeader(buffer);
            this.payload = Payloads.PayloadFactory.GetPayload(buffer);

            if (this.header == null) {
                Debug.error("header is null.");
                return;
            }

        }
    }


}