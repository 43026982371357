
namespace spacedesk.Model {

    export class Resolution {
        public width: number;
        public height: number;

        constructor(width = 0, height = 0) {
            this.width = width;
            this.height = height;
        }

        public static GetScreenResolution(): Resolution {

            if (Utils.isDesktopDevice) {
                return Resolution.GetFullScreenResolution();
            }

            let width = window.innerWidth;
            let height = window.innerHeight;

            width *= window.devicePixelRatio;
            height *= window.devicePixelRatio;

            return new Resolution(width, height);
        }

        public static GetFullScreenResolution(): Resolution {
            return new Resolution(window.screen.width, window.screen.height);
        }

    }
}