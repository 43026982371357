/// <reference path="../../../ProtocolDefinition.ts" />
/// <reference path="../../../../Resolution.ts" />
/// <reference path="../../../../Options.ts" />
/// <reference path="../../../../Rect.ts" />
/// <reference path="./GenericInputHeader.ts" />
/// <reference path="../../../ArrayExtensions.ts" />
/// <reference path="../../../../InputEnums.ts" />

namespace spacedesk.Model.Protocol.Packet.Header.KVM {

    export class TouchInputHeader extends GenericInputHeader {

        public type = ProtocolHeaderType.Touch;

        public position = new spacedesk.Model.Point(0, 0);

        public resX = 0;
        public resY = 0;

        public touchState : TouchState;

        public date : Date;

        public GetBytes(): Uint8Array {

            const POSITION_TYPE = 0;
            const POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER = 4;
            const POSITION_X = 8;
            const POSITION_Y = 12;
            const POSITION_RES_X = 16;
            const POSITION_RES_Y = 20;
            const POSITION_FLAGS = 24;
            const POSITION_TIMESTAMP_MILLISECONDS =28;

            let header = new Uint8Array(ProtocolDefinition.headerLength);
            let flags = 0x0;

            if (Model.Options.currentOptions.kvmAbsoluteTouchPosition) {
                flags |= 0x01;
            }

            header.assignInteger32(POSITION_TYPE, this.type);
            header.assignInteger32(POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER, 0);

            header.assignInteger32(POSITION_X, this.position.x);
            header.assignInteger32(POSITION_Y, this.position.y);

            header.assignInteger32(POSITION_RES_X, this.resX);
            header.assignInteger32(POSITION_RES_Y, this.resY);

            if(this.touchState === TouchState.TouchStateDown) {
                flags = flags | 0x2;
            }

            if(this.touchState === TouchState.TouchStateUp) {
                flags = flags | 0x4;
            }

            header.assignInteger32(POSITION_FLAGS, flags);

            this.date = new Date();
            header.assignInteger32(POSITION_TIMESTAMP_MILLISECONDS, this.date.getMilliseconds());

            return header;
        }
    }
}