/**
 * spacedesk
 */

/// <reference path="./Model/ExtensionMethods.ts"/>
/// <reference path="./Controller/AppController.ts"/>
/// <reference path="../tools/typings/tsd.d.ts"/>


import Controller = spacedesk.Controller;

const app = new Controller.AppController();
app.init();


