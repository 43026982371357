
namespace spacedesk.Model {

    export class Point {
        
        public x = 0;
        public y = 0;

        constructor(posX = 0, posY = 0) {

            this.x = posX;
            this.y = posY;

        }
    }
}