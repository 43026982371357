/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="./Header/GenericHeader.ts" />
/// <reference path="./SpacedeskPacket.ts" />

namespace spacedesk.Model.Protocol.Packet {

    export abstract class GenericRequestPacket extends SpacedeskPacket {

        public GetBufferData(): Uint8Array {

            let data = new Uint8Array(ProtocolDefinition.packetLength);
			
            // Set Header
            data.set(this.header.GetBytes());
			
            // Set Payload with offset 128 Bytes
            data.set(this.payload.data, ProtocolDefinition.headerLength);

            return data;
        }

        public dispose() {
            this.header = null;
            this.payload = null;
        }

    }
}