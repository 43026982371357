/// <reference path="../../tools/typings/tsd.d.ts" />
/// <reference path="../Model/Protocol/ProtocolDefinition.ts" />
/// <reference path="../Model/Protocol/ArrayExtensions.ts" />
/// <reference path="../Model/Protocol/Packet/FrameBufferPacket.ts" />
/// <reference path="../Model/Options.ts" />
/// <reference path="../Model/Render/RenderItemModel.ts" />
/// <reference path="./spacedeskController.ts" />
/// <reference path="./DiagnosticController.ts" />


namespace spacedesk.Controller {

    import Model = spacedesk.Model;
    import RenderModel = Model.Render.RenderItemModel;
    import FrameBufferPacket = Model.Protocol.Packet.FrameBufferPacket;


    export class ImageController {

        public imageReadyQueue = new Array<Model.Render.RenderItemModel>();

        private onImageReady = new SpacedeskEvent<void>();
        private onImageError = new SpacedeskEvent<string>();

        public push(data: FrameBufferPacket) {
            this.renderImage(data);
        }

        public cleanUpModel(model: Model.Render.RenderItemModel) {

            // Unregister Events
            model.image.onload = undefined;
            model.image.onerror = undefined;

            let url = (model.image as HTMLImageElement).src;

            // Unregister Events
            (model.image as HTMLImageElement).src = "";

            if (!Utils.isIE) {
                // Remove Image
                model.image.remove();
            }

            model.image = null;
            model = null;

            // Release Blob Url
            URL.revokeObjectURL(url);

        }

        public renderImage(data: FrameBufferPacket) {

            Debug.time("Image load");
            DiagnosticController.start(DiagnosticTimeTypes.decode);


            // New Model
            let model = new RenderModel(data);

            // Get Blob Url (Image)
            const url = this.getBlobUrl(data);

            if (url != null && url.length > 0) {

                let loader = (resolve: (value?: {} | Thenable<{}>) => void) => {
                    // create new Image Element
                    let image = new Image(data.header.resolution.width, data.header.resolution.height);

                    // Update Model
                    model.image = image;

                    image.onload = resolve;
                    image.onerror = (ev) => this.onImageErrorHandler(ev as ErrorEvent);

                    // Set image Path
                    image.src = url;
                };

                if ("Promise" in window) {
                    
                    let promise = new Promise((resolve, reject) => loader(resolve)).then(() => {
                        this.onImageReadyHandler(model);
                    });
                    
                }
                else {
                    loader(() => {
                        this.onImageReadyHandler(model);
                    });
                }

            }
            else {
                Debug.error("Blob Url is null");
                this.onImageError.trigger("Blob Url is null");
            }

        }

        private getBlobUrl(data: FrameBufferPacket) {

            switch (data.header.compressionType) {

                case Model.Protocol.CompressionType.Off:

                    // Create temporary Canvas
                    let backCanvas = document.createElement("canvas");

                    // Set Size
                    backCanvas.width = data.header.position.posX2 - data.header.position.posX;
                    backCanvas.height = data.header.position.posY2 - data.header.position.posY;

                    // Get Context and Image Data
                    let backCtx = backCanvas.getContext("2d");
                    let imgData = backCtx.getImageData(0, 0, backCanvas.width, backCanvas.height);
                    let rawData = imgData.data;

                    for (let i = 0; i < rawData.length; i += 4) {

                        // Switch from RGB to BGR
                        rawData[i] = data.payload.data[i + 2];
                        rawData[i + 1] = data.payload.data[i + 1];
                        rawData[i + 2] = data.payload.data[i];

                        // Set Alpha to 255 (visible)
                        rawData[i + 3] = 255;
                    }

                    // Put the image Data back to canvas
                    backCtx.putImageData(imgData, 0, 0);

                    // Create Url
                    let url = backCanvas.toDataURL();

                    backCtx = null;

                    backCanvas.remove();
                    backCanvas = null;

                    return url;

                case Model.Protocol.CompressionType.JPEG:
                case Model.Protocol.CompressionType.MJPEGD2:

                    // Check JPG Header
                    if (data.payload.data[0] === 255) {

                        // generate new Blob from data
                        let blob = new Blob([data.payload.data], {
                            type: "image/jpg"
                        });

                        // create url
                        return URL.createObjectURL(blob);
                    }

            }

            return null;
        }

        private onImageErrorHandler(ev: ErrorEvent) {
            // Inline Events
            // Error Event when something went wrong
            Debug.error("Image could not be loaded: " + ev.error);
            this.onImageError.trigger(ev.error);
        }

        private onImageReadyHandler(model: Model.Render.RenderItemModel) {
            // After Image Decoded
            Debug.timeEnd("Image load");
            DiagnosticController.end(DiagnosticTimeTypes.decode);

            Debug.log("Queue added - y:" + model.posY);
            let index = this.imageReadyQueue.push(model);

            // Callback
            this.onImageReady.trigger();
        }

        public get ImageReady(): ISpacedeskEvent<void> { return this.onImageReady; }

        public get ImageError(): ISpacedeskEvent<string> { return this.onImageError; }

    }

}