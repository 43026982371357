
namespace spacedesk {

    export class Debug {
        public static diagnostic = false;
        public static consoleTime = false;
        public static cursorProtocol = false;
        public static kvmProtocol = false;
        public static fpsMeter = false;

        public static time(name: string) {
            if (Debug.diagnostic && Debug.consoleTime) {
                Debug.log(name);
            }
        }

        public static timeEnd(name: string) {
            if (Debug.diagnostic && Debug.consoleTime) {
                Debug.log(name);
            }
        }

        public static log(message: string) {
            // if (Debug.diagnostic) {
                // Debug.log(message);
            // }
        }

        public static info(message: string) {
            if (Debug.diagnostic) {
                Debug.log(message);
            }
        }

        public static warn(message: string) {
            if (Debug.diagnostic) {
                Debug.log(message);
            }
        }

        public static error(message: string) {
            if (Debug.diagnostic) {
                Debug.log(message);
            }
        }

    }

}