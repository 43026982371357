/// <reference path="../../Model/Utils.ts" />
/// <reference path="../../../tools/typings/tsd.d.ts" />

namespace spacedesk.Controller.UI {

    export class AnimationController {

        public static showConnect(backgroundAnimated = true) {
            $(".hide-on-connect").addClass("hide");
            $(".show-on-connect").addClass("show");
            
            $("#start").show().removeClass("hide");
            
            if (backgroundAnimated) {
                $("#start").addClass("animate");
            }
            
            // $("#start header").addClass("animate");
            $("#start").addClass("active");

            $("#buttonLogin").addClass("pulse");
            $("#buttonLogin").text("Connecting...");



            setTimeout(() => {
                let $icon = $("#icon");
                $icon.attr("src", $icon.data(Utils.isRetinaDisplay ? "animation2x" : "animation"));
            }, 200);
        }

        public static resetConnect(backgroundAnimated = true) {

            if (!backgroundAnimated) {
                $("#start").removeClass("animate");
            }

            $(".hide-on-connect").removeClass("hide");
            $(".show-on-connect").removeClass("show");
            

            $("#start").removeClass("active");
            $("#buttonLogin").removeClass("pulse");
            $("#buttonLogin").text("Connect");

            setTimeout(() => {
                let $icon = $("#icon");
                $icon.attr("src", $icon.data(Utils.isRetinaDisplay ? "src2x" : "src"));
            }, 200);

        }

        public static showStart(backgroundAnimated = true) {

            if (!backgroundAnimated) {
                $("#start").removeClass("animate");
            }
            
            $("#center-stage").addClass("hide");
            $("#start").show().removeClass("hide");
        }

        public static showErrorState(errorMessage: string) {
            this.resetConnect();

            $("#start").addClass("error").addClass("active");
            $("#input").hide();
            $("#errorWrapper").show();
            $("#errorText").html(errorMessage);
/*
            setTimeout(() => {
                $("#start").removeClass("active");
                setTimeout(() => {
                    $("#start").removeClass("error");
                }, 2000);
            }, 1000);*/
        }

        public static showDesktop() {
            $("#start").addClass("hide");
            $("#center-stage").removeClass("hide");
        }

    }
}