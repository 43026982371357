/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../ViewController.ts" />
/// <reference path="KVMBaseController.ts" />
/// <reference path="../UI/PointerLockController.ts" />
/// <reference path="../../Model/Debug.ts" />
/// <reference path="../../Model/InputEnums.ts" />
/// <reference path="../../Model/Point.ts" />
/// <reference path="../../Model/Rect.ts" />
/// <reference path="../../Model/Protocol/Packet/KVMPacket.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/GenericInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/KeyboardInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/MouseInputHeader.ts" />
/// <reference path="../../Model/Protocol/ProtocolDefinition.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/IdentificationHeader.ts" />


namespace spacedesk.Controller.KVM {

    import ProtocolDefinition = spacedesk.Model.Protocol.ProtocolDefinition;
    import Packet = spacedesk.Model.Protocol.Packet;
    import KVM = spacedesk.Model.Protocol.Packet.Header.KVM;
    import KeyState = spacedesk.Model.KeyState;
    import MouseButton = spacedesk.Model.MouseButton;
    import MouseState = spacedesk.Model.MouseState;
    import Point = spacedesk.Model.Point;
    import PointerLockController = Controller.UI.PointerLockController;

    export class MouseController extends Controller.KVM.KVMBaseController {

        protected addEventListener() {

            // Browser compatibility first Chrome/Safari, Firefox than IE
            ViewController.Canvas.addEventListener("mousewheel", this.mouseWheelEvent.bind(this), false);
            ViewController.Canvas.addEventListener("DOMMouseScroll", (event) => this.mouseWheelEvent.bind(this), false);
            ViewController.Canvas.addEventListener("onmousewheel", (event) => this.mouseWheelEvent.bind(this), false);

            if (Model.Options.currentOptions.kvmKeyboard) {
                // Keyboard
                window.addEventListener("keydown", this.keyDownEvent.bind(this), false);
                window.addEventListener("keyup", this.keyUpEvent.bind(this), false);
            }

            // Prevent Double click, otherwise it will select the elements
            ViewController.Canvas.addEventListener("dblclick", (event) => event.preventDefault(), false);

            // Mouse Events
            ViewController.Canvas.addEventListener("mouseup", this.mouseUpEvent.bind(this), false);
            ViewController.Canvas.addEventListener("mousedown", this.mouseDownEvent.bind(this), false);

            // Move Events
            ViewController.Canvas.addEventListener("mousemove", this.mouseMoveEvent.bind(this), false);
        }

        protected removeEventListener() {

            if (Model.Options.currentOptions.kvmKeyboard) {
                window.removeEventListener("keydown", this.keyDownEvent);
                window.removeEventListener("keyup", this.keyUpEvent);
            }

            ViewController.Canvas.removeEventListener("mouseup", this.mouseUpEvent);
            ViewController.Canvas.removeEventListener("mousedown", this.mouseDownEvent);
            ViewController.Canvas.removeEventListener("mousemove", this.mouseMoveEvent);

            ViewController.Canvas.removeEventListener("mousewheel", this.mouseWheelEvent);
            ViewController.Canvas.removeEventListener("DOMMouseScroll", this.mouseWheelEvent);
            ViewController.Canvas.removeEventListener("onmousewheel", this.mouseWheelEvent);

        }


        private mouseUpEvent(event: MouseEvent) {
            if(!Model.Options.currentOptions.kvmControl) {
                return;
            }
            this.sendMouse(event.button, MouseState.Up);
        }

        private mouseDownEvent(event: MouseEvent) {
            if(!Model.Options.currentOptions.kvmControl) {
                return;
            }
            if (PointerLockController.pointerLockEnabled && !PointerLockController.pointerLockActivated) {
                PointerLockController.requestPointerLock();
                return;
            }
            this.sendMouse(event.button, MouseState.Down);
        }

        private mouseMoveEvent(event: MouseEvent) {           
            // is TouchEvent and pointerLockEnabled but not activated return.
            // Important when you using the menu
            if(!Model.Options.currentOptions.kvmControl) {
                return;
            }
            if (PointerLockController.pointerLockEnabled && !PointerLockController.pointerLockActivated) {
                return;
            } 
            let position = this.getPointerPosition(event);
            this.sendMouse(MouseButton.None, MouseState.Move, position);
            
        }

        private mouseWheelEvent(event: MouseWheelEvent | Event) {           
            if (PointerLockController.pointerLockEnabled && !PointerLockController.pointerLockActivated) {
                return;
            }
            this.sendMouse(MouseButton.None, MouseState.Scroll, null, (event as MouseWheelEvent).wheelDelta);
        }

        private getPointerPosition(event: MouseEvent): Point {

            let scaleX = ViewController.DesktopResolution.width / ViewController.Canvas.clientWidth;
            let scaleY = ViewController.DesktopResolution.height / ViewController.Canvas.clientHeight;

            let newPosition = new Point(0, 0);

            // is it a mouse event?

            let mouseEvent = (event as MouseEvent);

            // If Point Lock activated use movementX and movementY!
            if (UI.PointerLockController.pointerLockEnabled && UI.PointerLockController.pointerLockActivated) {
                return new Point(mouseEvent.movementX, mouseEvent.movementY);
            } 
           
            newPosition = new Point(mouseEvent.clientX, mouseEvent.clientY);

            if (newPosition.x > 0 || newPosition.y > 0) {

                // Diff between new and old Position
                let deltaX = newPosition.x - this.lastPosition.x;
                let deltaY = newPosition.y - this.lastPosition.y;

                deltaX = deltaX * scaleX;
                deltaY = deltaY * scaleY;

                // Save the touch start position for later (!important)
                this.lastPosition = newPosition;

                return new Point(deltaX, deltaY);
            }

            return new Point(0, 0);            
        }
    }

}