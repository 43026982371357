/// <reference path="../../tools/typings/tsd.d.ts" />
/// <reference path="../Model/Debug.ts" />

namespace spacedesk.Controller {

    export class DiagnosticController {

        private static rS: any = DiagnosticController.createInstance();
        private static counter: { [id: string] : number; } = {};

        public static count(type: DiagnosticCountTypes) {
            if (typeof this.rS !== "undefined") {
                let name = this.getRenderingCountName(type);

                let val = this.counter[name] || 0;
                this.rS(name).set(val++);
                this.counter[name] = val;
            }
        }

        public static start(type: DiagnosticTimeTypes) {
            if (typeof this.rS !== "undefined") {
                let name = this.getRenderingTypeName(type);
                this.rS(name).start();
            }
        }

        public static end(type: DiagnosticTimeTypes) {
            if (typeof this.rS !== "undefined") {
                let name = this.getRenderingTypeName(type);
                this.rS(name).end();
            }
        }

        public static meterRAF() {
            if (typeof this.rS !== "undefined") {
                this.rS("raf").tick();
            }
        }

        public static meterFPS() {
            if (typeof this.rS !== "undefined") {
                this.rS("fps").frame();
            }
        }

        public static update() {
            if (typeof this.rS !== "undefined") {
                this.rS().update();
            }
        }

        private static createInstance() {
            if (Debug.fpsMeter) {

                return new rStats({
                    values: {
                        raf: { caption: "Last rAF (ms)" },
                        fps: { caption: "Framerate (FPS)", below: 25 },
                        frame: { caption: "View Render (ms)", over: 20 },
                        decode: { caption: "Image Decode (ms)", over: 20 },
                        render: { caption: "WebGL Render (ms)" },
                        cursor: { caption: "Cursor  Render (ms)" },
                        packets: { caption: "Packets received" },
                        drawing: { caption: "Drawing times" }
                    },
                    groups: [
                        { caption: "Framerate", values: ["fps", "raf"] },
                        { caption: "Rendering", values: ["frame", "decode", "render", "cursor"] },
                        { caption: "Stats", values: ["packets", "drawing"] }
                    ],
                    css: []
                });
            }

            return undefined;
        }

        private static getRenderingTypeName(type: DiagnosticTimeTypes) {
            
            switch (type) {
                case DiagnosticTimeTypes.frame:
                    return "frame";
                case DiagnosticTimeTypes.render:
                    return "render";
                case DiagnosticTimeTypes.decode:
                    return "decode";
                case DiagnosticTimeTypes.cursor:
                    return "cursor";
            
                default:
                    return "";
            }
        }

        private static getRenderingCountName(type: DiagnosticCountTypes) {
            
            switch (type) {
                case DiagnosticCountTypes.packets:
                    return "packets";
                case DiagnosticCountTypes.drawing:
                    return "drawing";
            
                default:
                    return "";
            }
        }
    }

    export enum DiagnosticTimeTypes {
        frame = 0,
        decode = 1,
        render = 2,
        cursor = 3,
    }

    export enum DiagnosticCountTypes {
        packets = 0,
        drawing = 1,
    }
}