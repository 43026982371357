/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="../../../Resolution.ts" />
/// <reference path="../../../Options.ts" />
/// <reference path="../../../Rect.ts" />
/// <reference path="./GenericHeader.ts" />
/// <reference path="../../ArrayExtensions.ts" />

namespace spacedesk.Model.Protocol.Packet.Header {

    export class CursorPositionHeader extends GenericHeader {

        public position: Rect;

        public visible: boolean;

        public GetBytes(): Uint8Array {
            return null;
        }

        public LoadFromBuffer(buffer: Uint8Array) {

            let posX = buffer.getInteger32Value(8);
            let posY = buffer.getInteger32Value(12);

            this.position = new Rect(posX, posY, 0, 0);

            let visibleValue = buffer.getInteger32Value(16);
            this.visible = visibleValue === 1;
        }
    }
}