interface ISpacedeskEvent<T> {
    on(handler: { (data?: T): void }): void;
    off(handler: { (data?: T): void }): void;
}

class SpacedeskEvent<T> implements ISpacedeskEvent<T> {
    private handlers: { (data?: T): void; }[] = [];

    public on(handler: { (data?: T): void }) {
        this.handlers.push(handler);
    }

    public off(handler: { (data?: T): void }) {
        this.handlers = this.handlers.filter(h => h !== handler);
    }

    public trigger(data?: T) {
        for (let i = 0; i < this.handlers.length; i++) {
            let element = this.handlers[i];
            element(data);
        }

        // this.handlers.slice(0).forEach(h => h(data));
    }
}