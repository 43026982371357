/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="./SpacedeskPacket.ts" />


namespace spacedesk.Model.Protocol.Packet {

    export class CursorPositionPacket extends SpacedeskPacket {

        public header: Header.CursorPositionHeader;

    }
}