/// <reference path="../../Model/Utils.ts" />
/// <reference path="../../../tools/typings/tsd.d.ts" />

namespace spacedesk.Controller.UI {

    export class OrientationController {

        private static timeoutHandle = -1;
        private static onOrientationChanged = new SpacedeskEvent<void>();
        public static get OrientationChanged(): ISpacedeskEvent<void> { return this.onOrientationChanged; }
        
        public static registerEvents() {
            window.addEventListener("orientationchange", this.callback, false);
            window.addEventListener("resize", this.callback, false);
        }

        public static resetZoom() {

            let meta = document.querySelector("meta[name=viewport]");

            meta.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");

            window.setTimeout(function() {
                meta.setAttribute("content", "minimal-ui, initial-scale=1.0, user-scalable=yes, width=device-width");
            }, 200);
        }
        
        private static callback() {
                        
            if (!OrientationController.onOrientationChanged) {
                OrientationController.onOrientationChanged.trigger();
            }
            
            if (OrientationController.timeoutHandle > 0) {
                window.clearTimeout(OrientationController.timeoutHandle);
            }

            OrientationController.timeoutHandle = window.setTimeout(function() {
                OrientationController.timeoutHandle = -1;
                OrientationController.resetZoom();
            }, 200);
        }

    }
}