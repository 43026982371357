/// <reference path="../../Options.ts" />

/// <reference path="./Header/IdentificationHeader.ts" />
/// <reference path="./Payloads/IdentificationPayload.ts" />
/// <reference path="./GenericRequestPacket.ts" />

namespace spacedesk.Model.Protocol.Packet {

    export class IdentificationPacket extends GenericRequestPacket {

        constructor(settings: Options, protocolType?: ProtocolClientType) {
            super();

            this.header = new Header.IdentificationHeader(settings, protocolType);
            this.payload = new Payloads.IdentificationPayload();
        }
    }
}