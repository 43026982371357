namespace spacedesk.Model.Protocol {

    export class ProtocolDefinition {

        public static versionNumberMajor = 4;
        public static versionNumberMinor = 8;


        public static headerLength = 128;
        public static payloadLength = 334;
        public static packetLength = ProtocolDefinition.headerLength + ProtocolDefinition.payloadLength;
        public static headerKVMLength = 28;
    }

    export enum ProtocolHeaderType {

        Identification = 0,
        Ping = 1,

        FrameBuffer = 2,
        Visibility = 3,
        CursorPosition = 4,
        CursorBitmap = 5,
        
        PowerSuspendHibernate = 6,

        FlowControlAck = 7,
        Disconnect = 8,
        Rotation = 9,
        Mouse = 10,
        Keyboard = 11,
        Touch = 12,

        Max = 13
    }

    /*export enum ProtocolHeaderTypeInput {
        Connect = 0,
        Mouse = 1,
        Keyboard = 2,
        Disconnect = 3
    }*/

    export enum ProtocolClientType {
        WindowsRemoteMonitor = 0,
        WebBrowser = 1,
        UsbCableApple = 2,
        KvmWindows = 3,
        Max = 4
    }

    export enum ProtocolColorType {
        RGB8 = 0,
        RGB16 = 1,
        RGB24 = 2,
        RGBX32 = 3,
        RGBA32 = 4,
        YUV444 = 5,
        YUV422 = 6,
        YUV420 = 7,
        Max = 8
    }

    export enum CompressionType {
        Off = 0,
        H264 = 1,
        Unused1 = 2,
        JPEG = 3,
        MJPEGD2 = 4,
    }

    export enum CursorType {
        Monochrome = 1,
        Default = 2,
        MaskedColor = 4,
    }

    // See https://msdn.microsoft.com/en-us/library/windows/desktop/ms646260(v=vs.85).aspx
    export enum MouseEvents {
        Absolute = 0x8000,
        LeftDown = 0x0002,
        LeftUp = 0x0004,
        MiddleDown = 0x0020,
        MiddleUp = 0x0040,
        Move = 0x0001,
        RightDown = 0x0008,
        RightUp = 0x0010,
        Wheel = 0x0800,
        XDown = 0x0080,
        XUp = 0x100,
        HWheel = 0x1000,
        VirtualDesk = 0x4000
    }

    export enum KeyEvents {
        /// If specified, the scan code was preceded by a prefix byte that
        /// has the value 0xE0 (224).
        ExtendKey = 0x0001,

        /// If specified, the key is being released. If not specified, the
        /// key is being pressed.
        KeyUp = 0x0002,

        /// If specified, wScan identifies the key and wVk is ignored. 
        ScanCode = 0x0008,

        /// If specified, the system synthesizes a VK_PACKET keystroke. The
        /// wVk parameter must be zero. This flag can only be combined with
        /// the KEYUP flag.  
        Unicode = 0x0004
    }

    export enum TouchState {
        TouchStateUp = 0,
        TouchStateDown = 1,
        TouchSateNone = 2
    }
}