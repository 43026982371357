/// <reference path="../../Model/Utils.ts" />
/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../ViewController.ts" />
/// <reference path="../UI/PointerLockController.ts" />

namespace spacedesk.Controller.UI {

    export class MenuController {

        private $element = $(".sliding-panel-content,.sliding-panel-fade-screen");
        private $mainStage = $("#canvas-wrapper");

        public toggleMenu(visible = true) {
            UI.OrientationController.resetZoom();

            this.$element.toggleClass("is-visible", visible);
            this.$mainStage.toggleClass("showMenu", visible);

        }

        public toggleMenuEvent(evt: JQueryEventObject, preventDefault = true) {
            if (preventDefault) {
                evt.preventDefault();
                // evt.stopPropagation();          
            }

            this.toggleMenu(!this.$element.hasClass("is-visible"));
        }


        private registerEvents() {
            // ,.sliding-panel-fade-screen
            // , .sliding-panel-content a
            // , #center-stage
            $(".sliding-panel-content a").on("click touchend", (e) => this.toggleMenuEvent(e));
            $(".sliding-panel-button, .sliding-panel-close,.sliding-panel-fade-screen").on("click touchend", (e) => {
                this.toggleMenuEvent(e);

                if (!this.$element.hasClass("is-visible")) {
                    UI.PointerLockController.requestPointerLock();
                }
                else {
                    UI.PointerLockController.exitPointerLock();
                }

            });
            
            
            /*
            if (ViewController.fullscreenEnabled) {
                $("#center-stage").on("dblclick", (e) => {
                    ViewController.toggleFullscreen(true);
                    window.getSelection().empty();
                });
            }
            */

            // Open context menu
            $("#center-stage").on("contextmenu", (e) => {
                // Show menu if pressing control
                if (Model.Options.currentOptions.kvmControl && e.ctrlKey) {
                    // open menu
                    this.toggleMenu(true);
                }
                else {
                    this.toggleMenu(true);
                }
                return false;
            });
        }

        constructor() {
            this.registerEvents();
        }

    }
}