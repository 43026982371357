/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="../../../Resolution.ts" />
/// <reference path="../../../Options.ts" />
/// <reference path="../../../Rect.ts" />
/// <reference path="./GenericHeader.ts" />

namespace spacedesk.Model.Protocol.Packet.Header {

    export class CursorBitmapHeader extends GenericHeader {

        public resolution: Resolution;

        public pitch: number;

        public xHot: number;

        public yHot: number;

        public flags: CursorType;

        public GetBytes(): Uint8Array {
            return null;
        }

        public LoadFromBuffer(buffer: Uint8Array) {

            let width = buffer.getInteger32Value(8);
            let height = buffer.getInteger32Value(12);
            this.resolution = new Resolution(width, height);

            this.pitch = buffer.getInteger32Value(16);
            this.xHot = buffer.getInteger32Value(20);
            this.yHot = buffer.getInteger32Value(24);
            this.flags = buffer.getInteger32Value(28);

        }
    }
}