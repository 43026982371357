
/// <reference path="../../../tools/typings/tsd.d.ts"/>
/// <reference path="HelpController.ts" />
/// <reference path="../../Model/Resolution.ts" />

namespace spacedesk.Controller.UI {

    export class SettingsController {

        private static screenResolutions = [
            { "width": 0, "height": 0 },
            { "width": 800, "height": 600 },
            { "width": 1024, "height": 600 },
            { "width": 1024, "height": 768 },
            { "width": 1152, "height": 864 },
            { "width": 1280, "height": 720 },
            { "width": 1280, "height": 768 },
            { "width": 1280, "height": 800 },
            { "width": 1280, "height": 960 },
            { "width": 1280, "height": 1024 },
            { "width": 1360, "height": 768 },
            { "width": 1366, "height": 768 },
            { "width": 1400, "height": 1050 },
            { "width": 1440, "height": 900 },
            { "width": 1600, "height": 900 },
            { "width": 1600, "height": 1200 },
            { "width": 1680, "height": 1050 },
            { "width": 1920, "height": 1080 },
            { "width": 1920, "height": 1200 },
            { "width": 2048, "height": 1152 },
            { "width": 2560, "height": 1440 },
            { "width": 2560, "height": 1600 },
            { "width": 2560, "height": 1920 },
            { "width": 3840, "height": 2160 },
            { "width": 4096, "height": 2160 }
        ];

        private static minRes = {"width": 800, "height": 600};
        private static maxRes = {"width": 4096, "height": 2160};

        private toggleInEffect = "fadeIn";
        private toggleOutEffect = "fadeOut";

        private stepInEffect = "fadeIn";
        private stepOutEffect = "fadeOut";

        private settingsChanged = false;

        public get ChangedSettings() {
            return this.settingsChanged;
        }

        public static loadSettings(option: Model.Options) {
            this.quality = option.quality;
            this.customResolution = option.resolutionCustom;
            this.subsampling = option.subsampling;
            this.fullscreen = option.fullscreen;
            this.framerate = option.frameRate;

            this.kvmEnabled = option.kvmControl;
            this.kvmKeyboardEnabled = option.kvmKeyboard;
            this.kvmTouchAbsolute = option.kvmAbsoluteTouchPosition;

            this.useClientResolution = option.useClientResolution;
            this.useCustomResolution = option.useCustomResolution;
        }

        public static saveSettings(option: Model.Options) {
            option.quality = this.quality;
            option.resolutionCustom = this.customResolution;
            option.subsampling = this.subsampling;
            option.fullscreen = this.fullscreen;
            option.frameRate = this.framerate;

            option.kvmControl = this.kvmEnabled;
            option.kvmKeyboard = this.kvmKeyboardEnabled;
            option.kvmAbsoluteTouchPosition = this.kvmTouchAbsolute;

            option.useClientResolution = this.useClientResolution;
            option.useCustomResolution = this.useCustomResolution;
        }



        public static get customResolution(): Model.Resolution {
            return new Model.Resolution($("#customResX").val(),  $("#customResY").val());
        }
        public static set customResolution(resolution: Model.Resolution) {          
            resolution = resolution || new Model.Resolution(0, 0);

            $("#customResX").val(resolution.width);
            $("#customResY").val(resolution.height);

            // let index = -1;
            // for (let i = 0; i < SettingsController.screenResolutions.length; i++) {
            //     if (SettingsController.screenResolutions[i].width === resolution.width &&
            //         SettingsController.screenResolutions[i].height === resolution.height) {
            //         index = i;
            //         break;
            //     }
            // }


            // if (index >= 0) {
            //     $("#customResolution option").removeAttr("selected").filter("[value=" + index + "]").prop("selected", true);
            // } 
        }

        public static get fullscreen(): boolean {
            return $("#checkboxFullscreen").is(":checked");
        }
        public static set fullscreen(value: boolean) {
            $("#checkboxFullscreen").prop("checked", value);
        }

        public static get framerate(): number {
            return Number($("input[name='framerate']:checked").val());
        }

        public static set framerate(value: number) {
            $("input[name='framerate']").removeAttr("checked").prop("checked", false);
            $("input[name='framerate'][value='" + value + "']").prop("checked", true);
        }

        public static get useClientResolution(): boolean {
            return $("#checkboxClientResolution").is(":checked");
        }
        public static set useClientResolution(value: boolean) {
            $("#checkboxClientResolution").prop("checked", value);
        }

        public static get useCustomResolution(): boolean {
            return $("#checkboxCustomResolution").is(":checked");
        }
        public static set useCustomResolution(value: boolean) {
            $("#checkboxCustomResolution").prop("checked", value);
        }

        public static get kvmEnabled(): boolean {
            return $("#checkboxKVM").is(":checked");
        }
        public static set kvmEnabled(value: boolean) {
            $("#checkboxKVM").prop("checked", value);
        }

        public static get kvmKeyboardEnabled(): boolean {
            return $("#checkboxKVMKeyboard").is(":checked");
        }
        public static set kvmKeyboardEnabled(value: boolean) {
            $("#checkboxKVMKeyboard").prop("checked", value);
        }

        public static get kvmTouchAbsolute(): boolean {
            return $("#checkboxKVMTouchAbsolute").is(":checked");
        }
        public static set kvmTouchAbsolute(value: boolean) {
            $("#checkboxKVMTouchAbsolute").prop("checked", value);
        }

        public static get quality(): number {
            return Number($("#rangeQuality").val());
        }
        public static set quality(value: number) {
            $("#rangeQuality").val(value);
        }

        public static get subsampling(): number {
            return Number($("input[name='subsampling']:checked").val());
        }
        public static set subsampling(value: number) {
            $("input[name='subsampling']").removeAttr("checked").prop("checked", false);
            $("input[name='subsampling'][value='" + value + "']").prop("checked", true);
        }


        public showSettingsReloadModal() {
            UI.OrientationController.resetZoom();
            $("#modalSettingsReload").prop("checked", true).change();
        }

        public hideSettingsReloadModal() {
            UI.OrientationController.resetZoom();
            $("#modalSettingsReload").prop("checked", false).change();
        }

        public showSettings(animated = true) {
            this.toggleSettings(true, animated);
        }

        public hideSettings(animated = true, focusInput = true) {
            this.toggleSettings(false, animated, focusInput);
        }

        public restoreSettings() {
            SettingsController.loadSettings(Model.Options.currentOptions);
            this.settingsChanged = false;
        }

        private toggleSettings(visible = true, animated = true, focusInput = true) {
            let $inputs = $("#form-input");
            let $settings = $("#settings");

            this.setKvmKeyboardState();

            UI.OrientationController.resetZoom();
            $("#experimental").toggle(Model.Options.currentOptions.experimental);

            if (visible && $settings.hasClass(this.toggleInEffect) || !visible && $settings.hasClass(this.toggleOutEffect)) {
                return;
            }

            $inputs.removeClass(this.toggleInEffect).removeClass(this.toggleOutEffect);
            $settings.removeClass(this.toggleInEffect).removeClass(this.toggleOutEffect);

            HelpController.hideHelp();

            $("#start footer").toggle(!visible);

            if (animated) {
                if (visible) {
                    $inputs.addClass(this.toggleOutEffect);
                }
                else {
                    $settings.addClass(this.toggleOutEffect);
                    this.settingsChanged = false;
                }

                setTimeout(() => {
                    if (visible) {
                        $inputs.hide();
                        $settings.show().addClass(this.toggleInEffect);
                    }
                    else {
                        $settings.hide();
                        $inputs.show().addClass(this.toggleInEffect);

                        if (focusInput) {
                            setTimeout(() => {
                                $("#server").focus().click();
                            }, 400);
                        }
                    }
                }, 300);
            }
            else {
                if (visible) {
                    $inputs.hide();
                    $settings.show();
                }
                else {
                    $settings.hide();
                    $inputs.show();

                    this.settingsChanged = false;
                    if (focusInput) {
                        $("#server").focus().click();
                    }
                }
            }


        }

        private registerEvents(option: Model.Options) {

            // Load screen resolutions
            $.each(SettingsController.screenResolutions, function (index, resolution) {

                let text = resolution.width + "x" + resolution.height;

                if (resolution.width <= 0) {
                    text = "Resolutions...";
                }

                $("#customResolution").append($("<option/>", {
                    value: index,
                    text: text
                }));
            });

            // Set default Value to 'Resolutions...'
            $("#customResolution option[value='0']").prop("selected", true);

            // Res events...
            $("#customResolution").on("change", () => this.onChangeCustomResolution());
            $("#customResX").on("change", () => this.onChangeCustomResolutionX());
            $("#customResY").on("change", () => this.onChangeCustomResolutionY());


            SettingsController.loadSettings(option);

            let res = Model.Resolution.GetScreenResolution();
            $("#resolutionSettings").html(res.width + "x" + res.height);


            $("input, select", $("#settings")).bind("change paste keyup", () => {
                this.settingsChanged = true;
            });

            $("#checkboxKVM").on("change", () => this.setKvmKeyboardState());
            $("#checkboxKVMTouchAbsolute").on("change", () => this.setKvmAbsoluteState());

            $("#checkboxCustomResolution").on("change", () => this.setCustomeResolutionState());
            $("#checkboxClientResolution").on("change", () => this.setNativeResulotionState());
        }

        private setKvmKeyboardState() {

            let checked = $("#checkboxKVM").is(":checked");

            if (checked) {
                $("#checkboxKVMKeyboard").removeAttr("disabled");
                $("#checkboxKeyboard").removeClass("disabled");
            }
            else {
                $("#checkboxKeyboard").addClass("disabled");
                $("#checkboxKVMKeyboard").prop("disabled", true);
                $("#checkboxKVMKeyboard").prop("checked", false);
                $("#checkboxKVMTouchAbsolute").prop("checked", false);
            }
        }

        private onChangeCustomResolution() {
            let selected = SettingsController.screenResolutions[$("#customResolution").val()];

            if(selected.width === 0) {
                return;
            }

            $("#customResX").val(selected.width);
            $("#customResY").val(selected.height);

            $("#customResolution option").removeAttr("selected").filter("[value=0]").prop("selected", true);
        }

        private onChangeCustomResolutionX() {
            let currVal = $("#customResX").val();
            let needUpdate = false;

            if((currVal % 2) !== 0) {
                currVal = currVal - 1;
                needUpdate = true;
            }
            if(currVal < SettingsController.minRes.width) {
                currVal = SettingsController.minRes.width;
                needUpdate = true;
            }
            if(currVal > SettingsController.maxRes.width) {
                currVal = SettingsController.maxRes.width;
                needUpdate = true;
            }

            if(needUpdate) {
                $("#customResX").val(currVal);
            }
        }

        private onChangeCustomResolutionY() {
            let currVal = $("#customResY").val();
            let needUpdate = false;

            if((currVal % 2) !== 0) {
                currVal = currVal - 1;
                needUpdate = true;
            }
            if(currVal < SettingsController.minRes.height) {
                currVal = SettingsController.minRes.height;
                needUpdate = true;
            }
            if(currVal > SettingsController.maxRes.height) {
                currVal = SettingsController.maxRes.height;
                needUpdate = true;
            }
            
            if(needUpdate) {
                $("#customResY").val(currVal);
            }
        }



        private setKvmAbsoluteState() {
            let checked = $("#checkboxKVM").is(":checked");

            if (!checked) {
                $("#checkboxKVMTouchAbsolute").prop("checked", false);
            }  
        }

        private setNativeResulotionState() {
            let checked = $("#checkboxCustomResolution").is(":checked");

            if(!checked) {
                $("#checkboxClientResolution").prop("checked", true);
            }
        }

        private setCustomeResolutionState() {
            let checked = $("#checkboxClientResolution").is(":checked");

            if(!checked) {
                $("#checkboxCustomResolution").prop("checked", true);
            }
        }

        constructor(option: Model.Options) {
            this.registerEvents(option);
        }

    }
}