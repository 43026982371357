
namespace spacedesk.Model.Protocol.Packet.Header {

    interface IPacketHeader {
        identification: ProtocolHeaderType;

        countByteDataFollowingHeader: number;
    }

    export abstract class GenericHeader implements IPacketHeader {

        public identification: ProtocolHeaderType;

        public countByteDataFollowingHeader = ProtocolDefinition.payloadLength;

        public abstract GetBytes(): Uint8Array;

        public abstract LoadFromBuffer(buffer: Uint8Array): void;

        public constructor(buffer?: Uint8Array) {

            if (buffer != null) {

                this.identification = buffer.getInteger32Value(0);
                this.countByteDataFollowingHeader = buffer.getInteger32Value(4);

                this.LoadFromBuffer(buffer);
            }

        }
    }


}