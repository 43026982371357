/// <reference path="../Model/Utils.ts" />
/// <reference path="../Model/Events.ts" />
/// <reference path="./SpacedeskController.ts" />
/// <reference path="./ViewController.ts" />
/// <reference path="./ImageController.ts" />
/// <reference path="./DesktopController.ts" />
/// <reference path="../Model/ConnectionState.ts" />

/// <reference path="../Model/Protocol/Packet/FrameBufferPacket.ts" />
/// <reference path="../Model/Protocol/Packet/Header/VisibilityHeader.ts" />

namespace spacedesk.Controller {

    import Models = spacedesk.Model;

    export class BroadcastController {
        private static broadcastLookup = false;

        public static addBroadcastServer(server: string) {
            if (!this.broadcastLookup) {
                this.registerBroadcastLookup();
            }
            $("#server").val(server);

            console.log("New Broadcast server found: " + server);
            if ($("#broadcastlist a[data-server='" + server + "']").length <= 0) {
                $("#broadcastlist").append("<a href='#' class='input-button-large' data-server='" + server + "'>" + server + "</a>");
            }
        }

        public static registerBroadcastLookup() {
            this.broadcastLookup = true;
            $("#input").hide();
            $("#broadcast").show();
        }

        public static unregisterBroadcastLookup() {
            this.broadcastLookup = false;

            if ($("#broadcastlist a").length <= 0) {
                $("#input").show();
                $("#broadcast").hide();
            }
            else {
                $("#broadcast .description").html("Please select a server");
            }
        }
    }
}