/// <reference path="../../tools/typings/tsd.d.ts" />
/// <reference path="../Model/Debug.ts" />
/// <reference path="../Model/Utils.ts" />
/// <reference path="../Model/Protocol/ArrayExtensions.ts" />
/// <reference path="../Model/Options.ts" />
/// <reference path="../Model/Protocol/Packet/CursorBitmapPacket.ts" />
/// <reference path="../Model/Protocol/Packet/CursorPositionPacket.ts" />
/// <reference path="./SpacedeskController.ts" />
/// <reference path="./CursorController.ts" />
/// <reference path="./AppController.ts" />
/// <reference path="./UI/OrientationController.ts" />
/// <reference path="./UI/FullscreenController.ts" />
/// <reference path="../Model/Protocol/Packet/FlowControlAckPacket.ts" />

/// <reference path="./RenderEngine/IRenderEngine.ts" />
/// <reference path="./RenderEngine/WebGLRender.ts" />
/// <reference path="./RenderEngine/CanvasRender.ts" />

namespace spacedesk.Controller {

    import Packet = spacedesk.Model.Protocol.Packet;
    import RenderEngine = spacedesk.Controller.RenderEngine;

    export class ViewController {

        private static viewId = "viewCanvas";
        private static viewControl = document.getElementById(ViewController.viewId) as HTMLCanvasElement;
        private static lastResolution = new Model.Resolution(0, 0);

        public stretchScreen = true;
        private isVisibile = false;

        // private context = ViewController.viewControl.getContext("2d") as CanvasRenderingContext2D;
        private renderEngine: RenderEngine.IRenderEngine;

        private desktopController = new DesktopController();
        private cursorController = new Controller.CursorController();
        private connectionController : Controller.ConnectionController;


        private viewWrapper = document.getElementById("canvas-wrapper");
        private labelDesktopSize = document.getElementById("desktopSize");

        private requestAnimFrame: (callback: () => void) => void = (function () {
            return window.requestAnimationFrame ||
                (<any>window).webkitRequestAnimationFrame ||
                (<any>window).mozRequestAnimationFrame ||
                (<any>window).oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback: any) {
                    window.setTimeout(callback, 1000 / 60, new Date().getTime());
                };
        })();

        public static get Canvas(): HTMLCanvasElement {
            return ViewController.viewControl;
        }

        public static get DesktopResolution(): Model.Resolution {
            return this.lastResolution;
        }


        public static get touchDevice(): boolean {
            return "ontouchstart" in window || "msmaxtouchpoints" in window.navigator || Modernizr.touchevents;
        }


        public init(cc: Controller.ConnectionController): boolean {

            // this.stats = FPSMeter.push(0);

            this.renderEngine = new RenderEngine.CanvasRender(ViewController.viewControl);
            this.renderEngine.init();
            

            ViewController.lastResolution = Model.Resolution.GetScreenResolution();

            this.desktopController.DesktopDrawed.on((data) => this.requestForUpdateDesktop());

            let result = this.desktopController.init(cc) && this.cursorController.init();

            if (!result) {
                return false;
            }

            this.setDesktopSize(ViewController.lastResolution);
            window.addEventListener("resize", () => this.setDesktopScaling());

            UI.OrientationController.OrientationChanged.on(() => {
                this.setDesktopScaling();
            });


            // Initial Screen drawing
            this.requestForUpdateDesktop();

            return result;
        }

        public pushFrameBuffer(data: Packet.FrameBufferPacket) {

            let newWidth = data.header.resolution.width;
            let newHeight = data.header.resolution.height;

            if (newWidth !== ViewController.lastResolution.width || newHeight !== ViewController.lastResolution.height) {
                ViewController.lastResolution = new Model.Resolution(newWidth, newHeight);
                this.setDesktopSize(ViewController.lastResolution);
            }

            // Add Model to Stack
            this.desktopController.pushFrameBuffer(data);
        }

        public drawCursor(data: Packet.CursorBitmapPacket) {
            this.cursorController.setCursorBitmap(data);
            this.requestForUpdateDesktop();
        }

        public setCursorPosition(data: Packet.CursorPositionPacket) {
            this.cursorController.setCursorPositon(data);
            this.requestForUpdateDesktop();
        }

        public setVisibility(visible: boolean) {

            Debug.info("Visibility changed to: " + visible);

            this.isVisibile = visible;

            $(ViewController.viewControl).toggle(visible);
            $(ViewController.viewControl).toggleClass("on", visible);
            $(ViewController.viewControl).toggleClass("off", !visible);

            this.setDesktopScaling();
        }

        private setDesktopSize(resolution: Model.Resolution) {

            Debug.info("Resolution changed to " + resolution.width + "x" + resolution.height);
            this.desktopController.resizeDesktop(resolution);
            this.renderEngine.onresize(resolution);

            ViewController.viewControl.width = resolution.width;
            ViewController.viewControl.height = resolution.height;

            this.setDesktopScaling();
        }

        private setDesktopScaling() {

            let $canvas = $(ViewController.viewControl);

            if (this.stretchScreen) {
                if (!$canvas.is(".upscaleY, .upscaleX")) {
                    $canvas.addClass("upscaleX").addClass("upscaleY");
                }
            }
            else {
                $canvas.toggleClass("upscaleX", (window.innerWidth < ViewController.lastResolution.width));
                $canvas.toggleClass("upscaleY", (window.innerHeight < ViewController.lastResolution.height));
            }

            let labelText = ViewController.lastResolution.width + " x " + ViewController.lastResolution.height;
            if ($canvas.is(".upscaleY, .upscaleX")) {
                labelText = labelText + " (" + ViewController.viewControl.offsetWidth + " x " + ViewController.viewControl.offsetHeight + " scaled)";
            }

            // Force height because of navigationbar 100vh is not working correctly.
            if (Utils.iOSDevice || Utils.isAndroid) {
                $canvas.css("height", $(window).height());
            }

            this.labelDesktopSize.innerHTML = labelText;
        }

        private requestForUpdateDesktop() {

            if (spacedesk.Utils.iOSDevice) {
                this.drawDesktop();
            }
            else {
                // Trigger request Frame
                this.requestAnimFrame.call(window, () => {
                    this.drawDesktop();
                });
            }
        }

        private drawDesktop() {

            DiagnosticController.meterRAF();

            if (document.hidden) {
                Debug.log("Document is hidden. No desktop drawing");
                return;
            }

            DiagnosticController.meterFPS();
            
            DiagnosticController.start(DiagnosticTimeTypes.frame);           

            if (this.renderEngine instanceof RenderEngine.CanvasRender) {
                this.renderEngine.onresize(this.renderEngine.resolution);
            }

/*
            // iOS needs a complete black screen 
            if (Utils.iOSDevice) {

                Debug.time("Clear Desktop");
                this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
                Debug.timeEnd("Clear Desktop");
            }
            */

            Debug.time("Draw Desktop");

            // Draw Desktop Background at Pos 0/0
            this.desktopController.drawDesktopOn(this.renderEngine);
            
/*
            let letters = "0123456789ABCDEF".split("");
            let color = "#";
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }

            this.context.fillStyle = color;
            this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height);
*/
            Debug.timeEnd("Draw Desktop");

            Debug.time("Draw Cursor");

            // Draw cursor if visible
            /*
            if (this.cursorController.CursorVisible) {
                this.cursorController.drawCursorOn(this.context);
            }
*/
            
            // Diagnostic
            Debug.timeEnd("Draw Cursor");           
            
            DiagnosticController.count(DiagnosticCountTypes.drawing);
            DiagnosticController.end(DiagnosticTimeTypes.frame);
            DiagnosticController.update();
        }

    }

}