/// <reference path="./Resolution.ts" />
/// <reference path="../Controller/UI/SettingsController.ts" />


namespace spacedesk.Model {

    interface IOptions {
        [idx: string]: any;
    }

    export class Options implements IOptions {

        [idx: string]: any;
        
        public static QUERYKEY_CONNECT_TO = "connectTo";
        
        private static localStorageKey = "options";
        private static instance: Options;
        
        
        // Server
        public server = "";

        // default port
        public port = 28252;

        // max. supported resolution
        public resolutionMaxWidth = 2560;
        public resolutionMaxHeight = 1600;

        public resolution = Resolution.GetScreenResolution();
        public resolutionCustom = new Resolution(1024, 768);

        public useClientResolution = true;
        public useCustomResolution = true;

        // Connection Timeout 20 Sec.
        public connectionTimeout = 20000;

        // Try 5 times
        public reconnections = 5;
        public reconnectionTimeout = 60000;

        // color depth / subsampling: yuv 4.2.0
        public subsampling = 2;
        // jpg-quality
        public quality = 40;
        // compression level
        public compression = 3;

        public fullscreen = true;

        public stretchScreen = true;

        public autoConnect = false;
        public queryConnect = false;
        
        public kvmControl = true;
        public kvmAbsoluteTouchPosition = false;
        public kvmKeyboard = false;
        public experimental = false;

        public frameRate = 0;
        // renderEngine: RenderEngines = RenderEngines.WebGL;
        
        
        public static get currentOptions() {
            if (this.instance == null) {
                this.loadCurrentOptions();
            }

            return this.instance;
        }

        public static loadCurrentOptions() {

            let settings = new Options();
                        
            try {
                if (localStorage.getItem(Options.localStorageKey) != null) {
                    this.parseJSON(settings, JSON.parse(localStorage.getItem(Options.localStorageKey)));
                }
            } catch (error) {
                spacedesk.Debug.error(error);
            }

            if (window.location.search.length > 0) {
                this.loadSettingsFromQuery(settings);
            }
                        
            this.instance = settings;
        }

        private static parseJSON(settings: Options, json: any) {

            for (let key in json) {
                if (json.hasOwnProperty(key)) {
                    let element = json[key];
                    if (settings[key] != null) {
                        settings[key] = element;
                    }
                }
            }

        }

        private static loadSettingsFromQuery(settings: Options) {

            let queries = window.location.search.substr(1).split("&");
            let args: any = {};

            for (let i = 0; i < queries.length; ++i) {
                let split = queries[i].split("=", 2);
                let key = split[0] as string;

                if (split.length === 1) {
                    args[split[0]] = "";
                }
                else {
                    let value = <any>decodeURIComponent(split[1].replace(/\+/g, " "));


                    if (key === Options.QUERYKEY_CONNECT_TO) {
                        settings.server = value;
                        settings.queryConnect = true;
                    }
                    else if (settings[key] != null) {
                        if (typeof (settings[key]) === "number") {
                            settings[key] = Number(value);
                        }
                        if (typeof (settings[key]) === "boolean") {
                            settings[key] = value === "true";
                        }
                        else {
                            settings[key] = value;
                        }
                    }
                }
            }

        }
        
        

        public updateScreenResolution() {
            this.resolution = Resolution.GetScreenResolution();
        }
        
        public getSocketUrl(): string {
            return "ws://" + this.server + ":" + this.port;
        }
        

        public saveOptions() {

            // this.server = $("#server").val();
            this.queryConnect = false;
            
            Controller.UI.SettingsController.saveSettings(this);
            
            try {
                localStorage.clear();
                localStorage.setItem(Options.localStorageKey, JSON.stringify(this));
            } catch (error) {
                spacedesk.Debug.error(error);
            }
        }

        public clearOptions() {
            try {
                localStorage.clear();
            } catch (error) {
                spacedesk.Debug.error(error);
            }
            
            Options.instance = null;
        }


    }
}