

// Extend Uint8Array with getInteger32Value and assignInteger32

interface Uint8Array {
    getInteger32Value(index: number): number;
    assignInteger32(index: number, value: number): void;
    assignInteger16(index: number, value: number): void;
}

Uint8Array.prototype.getInteger32Value = function(index: number): number {
    return this[index] | (this[index + 1] << 8) | (this[index + 2] << 16) | (this[index + 3] << 24);
};

Uint8Array.prototype.assignInteger32 = function(index: number, value: number): void {
    this[index] = value & 0xFF;
    this[index + 1] = (value & 0xFF00) >> 8;
    this[index + 2] = (value & 0xFF0000) >> 16;
    this[index + 3] = (value & 0xFF000000) >> 24;
};

Uint8Array.prototype.assignInteger16 = function(index: number, value: number): void {
    this[index] = value & 0xFF;
    this[index + 1] = (value & 0xFF00) >> 8;
};
