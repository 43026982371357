/// <reference path="../Model/Options.ts" />
/// <reference path="../Model/Events.ts" />
/// <reference path="../Model/Protocol/ProtocolDefinition.ts" />
/// <reference path="../Model/Protocol/Packet/Header/HeaderFactory.ts" />
/// <reference path="../Model/Protocol/Packet/Payloads/PayloadFactory.ts" />

/// <reference path="../Model/Protocol/Packet/IdentificationPacket.ts" />
/// <reference path="../Controller/UI/FullscreenController.ts" />

namespace spacedesk.Controller {

    import Models = spacedesk.Model;

    export class SpacedeskController {

        private websocket: WebSocket;
        private options: Models.Options;

        private timerTimeoutHandle: number;

        private initialPayloadTimeout = false;
        private initialPayloadTimeoutTime = 5000;
        private initialPayloadTimeoutId: number;


        private onConnectedTriggered = false;

        // Reconnection Things  
        private connectionTimeout = false;
        private reconnecting: boolean = false;
        private reconnections: number = 0;
        private shouldReconnectToServer: boolean = true;


        // Connected Properties
        private connected: boolean = false;
        private naturalClosed: boolean = false;
        // Important for Power Management Situation
        private screenVisible = false;

        // Events
        private onPacketReceived = new SpacedeskEvent<Model.Protocol.Packet.SpacedeskPacket>();
        private onConnected = new SpacedeskEvent<void>();
        private onDisconnected = new SpacedeskEvent<void>();
        private onReconnect = new SpacedeskEvent<void>();
        private onConnectionTimout = new SpacedeskEvent<void>();
        private onConnectionError = new SpacedeskEvent<string>();
        
        public get Connected() {
            return this.websocket != null && this.websocket.readyState === WebSocket.OPEN;
        }

        public connectToServer(settings: Models.Options) {

            this.options = settings;

            if (settings == null || settings.server == null) {
                Debug.error("settings model is null oder settings.server is null");
                return;
            }

            try {

                let url = this.options.getSocketUrl();

                this.onConnectedTriggered = false;

                this.websocket = new WebSocket(url);
                this.websocket.binaryType = "arraybuffer";

                this.websocket.onopen = () => this.onSocketOpened();
                this.websocket.onerror = (event: any) => this.onSocketError(event);
                this.websocket.onclose = (event: CloseEvent) => this.onSocketClose(event);
                this.websocket.onmessage = (message: MessageEvent) => this.onSocketMessageReceived(message);
				
                // Connection Timeout
                let time = (this.reconnecting ? this.options.reconnectionTimeout : this.options.connectionTimeout);


                this.timerTimeoutHandle = setTimeout(() => {
                    Debug.warn("Connection timeout.");
                    
                    this.connectionTimeout = true;
                    if (this.websocket != null) {
                        this.websocket.close();
                    }
                    this.connectionTimeout = false;
                    
                    this.onConnectionTimout.trigger();
                    
                }, time);
				
            }
            catch (e) {
                this.onConnectionError.trigger(this.getErrorCode(1));
                Debug.log(e);
            }

        }

        public closeConnection(message?: Uint8Array) {
            // Not here ... this.options.onDisconnected();

            this.naturalClosed = true;

            // Websocket object initialized?
            if (this.websocket) {

                // State is connected
                if (this.websocket.readyState === WebSocket.OPEN) {

                    if (message != null && message.length > 0) {
                        this.sendData(message);
                    }

                    this.websocket.close(1000);
                }
                else {

                    // clear reconnect Timer
                    clearTimeout(this.timerTimeoutHandle);
                    this.shouldReconnectToServer = false;
                }
            }
        }

        public sendData(buffer: Uint8Array) {
            if (this.Connected) {
                this.websocket.send(buffer);
            }
        }
                
        public sendInitialPayload(buffer: Uint8Array) {
            
            this.sendData(buffer);

            this.initialPayloadTimeoutId = setTimeout(() => {
                this.initialPayloadTimeout = true;
                this.onConnectionError.trigger(this.getErrorCode(2));
            }, this.initialPayloadTimeoutTime);
        }

        private onSocketOpened() {

            // Clear Timeout Handle
            clearTimeout(this.timerTimeoutHandle);

            // Reset Reconnection
            this.reconnecting = false;
            this.shouldReconnectToServer = true;
            this.reconnections = 0;


            this.connected = true;
            
            if (!this.onConnectedTriggered) {
                // Fire onConnected Event
                this.onConnectedTriggered = true;
                this.onConnected.trigger();
            }
        }

        private onSocketError(event: any) {
            // Send error code
            if (event.code == null || event.code === undefined) {
                event.code = 3;
            }
            else {
                this.onConnectionError.trigger(this.getErrorCode(event.code));
            }
        }

        private onSocketClose(event: CloseEvent) {
            let serverClosedSession = false;

            // Clear Timeout Timer
            clearTimeout(this.timerTimeoutHandle);

            // 
            this.websocket = null;

            // Do nothing when initialPayloadTimeout
            if (this.initialPayloadTimeout) {
                return;
            }

            // Got Connection timeout
            if (this.connectionTimeout) {
                return;
            }

            // Check Error Code
            if (event.code !== 1000) {

                let reason = this.getErrorCode(event.code, event);

                if (event.code === 1006) {
                    serverClosedSession = true;
                }
                else {
                    Debug.warn(reason);
                }
            }

            // Check for Power State Events
            // Dirty Hack: Currently we can only check the power state event when:
            //  - Server closes the Session
            if (this.connected && this.shouldReconnectToServer && serverClosedSession && !this.screenVisible) {
                Debug.info("Reconnect: Server closed the session due a Power State Event.");
                this.naturalClosed = false;
            }

            // Reset state
            this.connected = false;

            // Check if naturally Closed (by user.)
            if (this.naturalClosed) {

                // Everything is ok.
                this.naturalClosed = false;
                this.onDisconnected.trigger();
            }
            else {

                this.reconnecting = true;

                // Check for max reconnections
                if (this.reconnections <= this.options.reconnections) {
                    this.reconnections++;
                    this.onReconnect.trigger();

                    Debug.info("Reconnecting to server " + this.reconnections + " of " + this.options.reconnections + "");

                    // Reconnect to server

                    if (Model.Options.currentOptions.fullscreen) {
                        Controller.UI.FullscreenController.requestFullscreen();
                    }

                    this.connectToServer(this.options);
                }
                else {
                    Debug.warn("Could not reconnect to server. Max. Reconnections (" + this.options.reconnections + ") reached. Show Error.");
                    this.onDisconnected.trigger();
                }
            }

        }

        private onSocketMessageReceived(message: MessageEvent) {

            Debug.time("socket message");

            DiagnosticController.count(DiagnosticCountTypes.packets);

            if (this.initialPayloadTimeoutId > 0) {
                clearTimeout(this.initialPayloadTimeoutId);
                this.initialPayloadTimeoutId = 0;
            }

            // Check data size
            let data = new Model.Protocol.Packet.SpacedeskPacket(message.data);

            if (data != null) {
                this.onPacketReceived.trigger(data);

                if (data.header.identification === Model.Protocol.ProtocolHeaderType.Visibility) {
                    this.screenVisible = (data.header as Model.Protocol.Packet.Header.VisibilityHeader).visible;
                }
            }

            // Clean up
            data.header = null;
            data.payload = null;
            data = null;

            message.data = null;
            message = null;

            Debug.timeEnd("socket message");
        }




        private getErrorCode(code: number, event: CloseEvent = null): string {

            let reason = "";

            switch (code) {

                case 1:
                    reason = "Could not create Websocket";
                    break;

                case 2:
                    reason = "Server is not responding.";
                    break;

                case 3:
                    reason = "Connection timeout. Maybe a wrong IP-Address?";
                    break;

                case 1001:
                    reason = "An endpoint is \"going away\", such as a server going down or a browser having navigated away from a page.";
                    break;

                case 1002:
                    reason = "An endpoint is terminating the connection due to a protocol error";
                    break;

                case 1003:
                    reason = "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
                    break;

                case 1004:
                    reason = "Reserved. The specific meaning might be defined in the future.";
                    break;

                case 1005:
                    reason = "No status code was actually present.";
                    break;

                case 1006:
                    reason = "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
                    break;

                case 1007:
                    reason = "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [http://tools.ietf.org/html/rfc3629] data within a text message).";
                    break;

                case 1008:
                    reason = "An endpoint is terminating the connection because it has received a message that \"violates its policy\". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.";
                    break;

                case 1009:
                    reason = "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
                    break;

                case 1010:
                    // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
                    reason = "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " + event.reason;
                    break;

                case 1011:
                    reason = "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
                    break;

                case 1015:
                    reason = "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
                    break;

                default:
                    reason = "Unknown reason";
                    break;
            }

            return reason;
        }


        public get PacketReceived(): ISpacedeskEvent<Model.Protocol.Packet.SpacedeskPacket> { return this.onPacketReceived; }
        public get ConnectionEstablished(): ISpacedeskEvent<void> { return this.onConnected; }
        public get Reconnect(): ISpacedeskEvent<void> { return this.onReconnect; }
        public get ConnectionError(): ISpacedeskEvent<string> { return this.onConnectionError; }
        public get Disconnected(): ISpacedeskEvent<void> { return this.onDisconnected; }
        public get Timeout(): ISpacedeskEvent<void> { return this.onConnectionTimout; }
    }

}