/// <reference path="KVMBaseController.ts" />
/// <reference path="TouchController.ts" />
/// <reference path="MouseController.ts" />


namespace spacedesk.Controller.KVM {
    import KVM = spacedesk.Controller.KVM; 

    export class KVMFactory {
        
        public static Build(sc: SpacedeskController): KVMBaseController {

            // Check Touch
            if (ViewController.touchDevice) {
                console.log("Touchcontroller built");
                return new TouchController(sc);
            }
            else {
                console.log("Mousecontroller built");
                return new MouseController(sc);
            }
        }

    }

}