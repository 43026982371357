
namespace spacedesk.Model.Render {

    import FrameBufferPacket = Model.Protocol.Packet.FrameBufferPacket;

    export class RenderItemModel {

        public posX: number;
        public posY: number;

        public posX2: number;
        public posY2: number;

        get width(): number {
            return this.posX2 - this.posX;
        }
        get height(): number {
            return this.posY2 - this.posY;
        }

        public image: HTMLImageElement | HTMLCanvasElement;

        public isFragement = false;

        constructor(data?: FrameBufferPacket) {

            if (data != null) {
                
                this.posX = data.header.position.posX;
                this.posX2 = data.header.position.posX2;
                this.posY = data.header.position.posY;
                this.posY2 = data.header.position.posY2;
                this.isFragement = data.header.fragmentInfo === 1;
            }

        }

    }
}