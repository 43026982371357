
/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="./GenericPayload.ts" />

namespace spacedesk.Model.Protocol.Packet.Payloads {

    export class PayloadFactory {

        public static GetPayload(buffer: ArrayBuffer): GenericPayload {
            return new GenericPayload(new Uint8Array(buffer, ProtocolDefinition.headerLength));
        }

    }
}