/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="./SpacedeskPacket.ts" />
/// <reference path="./Header/KVM/GenericInputHeader.ts" />


namespace spacedesk.Model.Protocol.Packet {

    export class KVMPacket {

        public header: Header.KVM.GenericInputHeader;
        
        public constructor(header: Header.KVM.GenericInputHeader) {
            this.header = header;
        }
        
        public GetBufferData(): Uint8Array {
            return this.header.GetBytes();
        }

        public dispose() {
            this.header = null;
        }
    }
}