/// <reference path="../../tools/typings/tsd.d.ts" />
/// <reference path="../Model/Debug.ts" />
/// <reference path="../Model/Rect.ts" />
/// <reference path="../Model/Protocol/Packet/CursorBitmapPacket.ts" />
/// <reference path="../Model/Protocol/Packet/CursorPositionPacket.ts" />
/// <reference path="../Model/Protocol/ProtocolDefinition.ts" />


namespace spacedesk.Controller {

    import Packet = spacedesk.Model.Protocol.Packet;

    export class CursorController {

        public cursorCanvas = document.createElement("canvas");
        // public cursorCanvas = document.getElementById("cursor") as HTMLCanvasElement;

        private context = this.cursorCanvas.getContext("2d");

        private cursorPosition: spacedesk.Model.Rect;

        private cursorVisible = false;

        private cursorBuffer: Packet.CursorBitmapPacket;
        private lastMonoCursorPositionDrawed: spacedesk.Model.Rect;


        public get LastCursorPosition() {
            return this.cursorPosition;
        }
        public get CursorVisible() {
            return this.cursorVisible;
        }

        public init(): boolean {

            this.cursorPosition = new spacedesk.Model.Rect(0, 0, 0, 0);
            this.lastMonoCursorPositionDrawed = new spacedesk.Model.Rect(0, 0, 0, 0);

            // this.setupForTesting();
            this.context.transform(1, 0, 0, -1, 0, this.cursorCanvas.height);
            // this.context.globalCompositeOperation = "destination-over";
            return true;
        }

        public setCursorBitmap(bitmap: Packet.CursorBitmapPacket) {
            this.cursorBuffer = (JSON.parse(JSON.stringify(bitmap)));

            if (bitmap.header.flags === Model.Protocol.CursorType.Default) {
                this.drawDefaultCursor();
            }
        }


        public drawCursorOn(desktop: CanvasRenderingContext2D) {


            if (typeof this.cursorBuffer === "undefined") {
                return;
            }

            let posY = this.LastCursorPosition.posY;
            
            // If default cursor, it's already rendered, so just draw it to context.
            if (this.cursorBuffer.header.flags === Model.Protocol.CursorType.Default) {
                desktop.drawImage(this.cursorCanvas, this.LastCursorPosition.posX, posY, this.cursorCanvas.width, this.cursorCanvas.height, this.LastCursorPosition.posX, posY, this.cursorCanvas.width, this.cursorCanvas.height);
            }
            else {

                if (this.LastCursorPosition.posX !== this.lastMonoCursorPositionDrawed.posX || this.LastCursorPosition.posY !== this.lastMonoCursorPositionDrawed.posY) {
                    this.drawMonochromeCursor(desktop);
                }
                desktop.drawImage(this.cursorCanvas, this.LastCursorPosition.posX, posY, this.cursorCanvas.width, this.cursorCanvas.height, this.LastCursorPosition.posX, posY, this.cursorCanvas.width, this.cursorCanvas.height);
            }


        }

        public setCursorPositon(data: Packet.CursorPositionPacket) {

            if (Debug.cursorProtocol) {
                Debug.log("Cursor Position: X=" + data.header.position.posX + " Y=" + data.header.position.posY);
            }

            this.cursorPosition = data.header.position;
            this.cursorVisible = data.header.visible;
        }

        private drawDefaultCursor() {
            if (Debug.cursorProtocol) {
                Debug.info("Cursor Draw Bitmap");
            }

            DiagnosticController.start(DiagnosticTimeTypes.cursor);

            let bitmap = this.cursorBuffer;

            // Set new Resolution
            this.cursorCanvas.width = bitmap.header.resolution.width;
            this.cursorCanvas.height = bitmap.header.resolution.height;

            // Get screen
            let imgData = this.context.getImageData(0, 0, bitmap.header.resolution.width, bitmap.header.resolution.height);
            let data = imgData.data;

            // get RGBA values
            for (let i = 0; i < data.length; i += 4) {

                // Check for CursorType
                switch (bitmap.header.flags) {

                    case Model.Protocol.CursorType.Default:

                        this.ConvertRGBAToBGRA(bitmap, data, i);

                        break;
                }
            }

            // Put Cursor Bitmap on Canvas.
            this.context.putImageData(imgData, 0, 0);

            DiagnosticController.end(DiagnosticTimeTypes.cursor);
            
        }

        private drawMonochromeCursor(desktop: CanvasRenderingContext2D) {
            if (Debug.cursorProtocol) {
                Debug.info("Cursor Draw Bitmap");
            }
            let bitmap = this.cursorBuffer;

            // Set new Resolution
            this.cursorCanvas.width = bitmap.header.resolution.width;
            this.cursorCanvas.height = bitmap.header.resolution.height;

            // Get screen info
            let imgData = desktop.getImageData(this.LastCursorPosition.posX, this.LastCursorPosition.posY, bitmap.header.resolution.width, bitmap.header.resolution.height);
            let data = imgData.data;

            // get RGBA values
            for (let i = 0; i < data.length; i += 4) {

                // Check for CursorType
                switch (bitmap.header.flags) {

                    case Model.Protocol.CursorType.Monochrome:
                        
                        // is white (Red AND Green AND Blue)
                        // then use XOR
                        let xor = (bitmap.payload.data[i] & bitmap.payload.data[i + 1] & bitmap.payload.data[i + 2]) === 255;
                        
                        if (xor) {
                            // rgba to bgra
                            
                            // Blue
                            data[i] = bitmap.payload.data[i + 2] ^ data[i];
                            // Green
                            data[i + 1] = bitmap.payload.data[i + 1] ^ data[i + 1];
                            // Red
                            data[i + 2] = bitmap.payload.data[i] ^ data[i + 2];
                            // Alpha
                            data[i + 3] = bitmap.payload.data[i + 3] ^ data[i + 3];
                        }                        
                        else {
                            // rgba to bgra                            
                            data[i] = bitmap.payload.data[i + 2];
                            data[i + 1] = bitmap.payload.data[i + 1];
                            data[i + 2] = bitmap.payload.data[i];
                            data[i + 3] = 0;
                        }

                        break;

                    case Model.Protocol.CursorType.MaskedColor:

                        // if not visible use XOR

                        let xorMasked = (bitmap.payload.data[i] & bitmap.payload.data[i + 1] & bitmap.payload.data[i + 2]) === 0;

                        if (bitmap.payload.data[i + 3] !== 255) {
                            Debug.info("HEY");
                        }
                        if (xorMasked) {

                            data[i] = data[i] ^ bitmap.payload.data[i + 2];
                            data[i + 1] = data[i + 1] ^ bitmap.payload.data[i + 1];
                            data[i + 2] = data[i + 2] ^ bitmap.payload.data[i];
                            data[i + 3] = data[i + 3] ^ bitmap.payload.data[i + 3];
                        }
                        else {
                            data[i + 3] = 0;
                        }

                        break;
                }
            }

            // Put Cursor Bitmap on Canvas.
            this.context.putImageData(imgData, 0, 0);
            this.lastMonoCursorPositionDrawed = this.LastCursorPosition;
        }

        private ConvertRGBAToBGRA(source: Packet.CursorBitmapPacket, target: Uint32Array, atIndex: number, xOR = false) {

            if (xOR) {
                target[atIndex] ^= source.payload.data[atIndex + 2];
                target[atIndex + 1] ^= source.payload.data[atIndex + 1];
                target[atIndex + 2] ^= source.payload.data[atIndex];
                // target[atIndex + 3] ^= source.payload.data[atIndex + 3];
            }
            else {
                target[atIndex] = source.payload.data[atIndex + 2];
                target[atIndex + 1] = source.payload.data[atIndex + 1];
                target[atIndex + 2] = source.payload.data[atIndex];
                target[atIndex + 3] = source.payload.data[atIndex + 3];
            }
        }

    }

}