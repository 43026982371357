/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="../../../Resolution.ts" />
/// <reference path="../../../Options.ts" />
/// <reference path="../../../Rect.ts" />
/// <reference path="./GenericHeader.ts" />
/// <reference path="../../ArrayExtensions.ts" />

namespace spacedesk.Model.Protocol.Packet.Header {

    export class FrameBufferHeader extends GenericHeader {

        public resolution: Resolution;

        public pitch: number;
        public format: number;

        public position: Rect;

        public compressionType: CompressionType;

        public fragmentInfo: number;

        public GetBytes(): Uint8Array {
            return null;
        }

        public LoadFromBuffer(buffer: Uint8Array) {

            let width = buffer.getInteger32Value(8);
            let height = buffer.getInteger32Value(12);
            this.resolution = new Resolution(width, height);

            this.pitch = buffer.getInteger32Value(16);
            this.format = buffer.getInteger32Value(20);

            let posX = buffer.getInteger32Value(24);
            let posY = buffer.getInteger32Value(28);
            let posX2 = buffer.getInteger32Value(32);
            let posY2 = buffer.getInteger32Value(36);

            this.position = new Rect(posX, posY, posX2, posY2);

            this.compressionType = buffer.getInteger32Value(40);

            this.fragmentInfo = buffer.getInteger32Value(64);
        }
    }
}