/// <reference path="../../../ProtocolDefinition.ts" />
/// <reference path="../../../../Resolution.ts" />
/// <reference path="../../../../Options.ts" />
/// <reference path="../../../../Rect.ts" />
/// <reference path="./GenericInputHeader.ts" />
/// <reference path="../../../ArrayExtensions.ts" />
/// <reference path="../../../../InputEnums.ts" />

namespace spacedesk.Model.Protocol.Packet.Header.KVM {

    export class MouseInputHeader extends GenericInputHeader {


        public type = ProtocolHeaderType.Mouse;

        public position = new Point(0, 0);

        public wheelDelta = 0;

        public state: MouseState;

        public button: MouseButton;

        public GetBytes(): Uint8Array {

            const POSITION_TYPE = 0;
            const POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER = 4;
            const POSITION_X = 8;
            const POSITION_Y = 12;
            const POSITION_BUTTON_DATA = 16;
            const POSITION_BUTTON_FLAGS = 20;
            const POSITION_FLAGS = 24;

            let header = new Uint8Array(ProtocolDefinition.headerLength);

            header.assignInteger32(POSITION_TYPE, this.type);
            header.assignInteger32(POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER, 0);

            let flags = 0x0;

            switch (this.state) {
                case MouseState.Move:
                    header.assignInteger32(POSITION_X, this.position.x);
                    header.assignInteger32(POSITION_Y, this.position.y);
                    flags |= 0x01;
                    break;

                case MouseState.Scroll:
                    header.assignInteger32(POSITION_BUTTON_DATA, this.wheelDelta);
                    
                    flags |= MouseEvents.Wheel;                    
                    break;
                case MouseState.Up:
                case MouseState.Down:
                    let buttonValue = 0;

                    switch (this.button) {
                        case MouseButton.Left:
                            buttonValue = this.state === MouseState.Down ? MouseEvents.LeftDown : MouseEvents.LeftUp;
                            break;
                        case MouseButton.Right:
                            buttonValue = this.state === MouseState.Down ? MouseEvents.RightDown : MouseEvents.RightUp;
                            break;

                        default:
                            break;
                    }
                    console.log("FLAG = STATE - " + this.state + " BUTTON - " + this.button + " FLAG - " + buttonValue);
                    flags |= buttonValue;
                    break;
            }

            header.assignInteger32(POSITION_BUTTON_FLAGS, flags);
            header.assignInteger32(POSITION_FLAGS, 0);

            return header;
        }
    }
}