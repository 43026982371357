/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../../Model/Options.ts" />
/// <reference path="./IRenderEngine.ts" />
/// <reference path="../../Model/Render/WebGLHelper.ts" />
/// <reference path="../../Model/Debug.ts" />

namespace spacedesk.Controller.RenderEngine {

    import Model = spacedesk.Model;
    import WebGL = Model.Render.WebGLHelper;


    export class WebGLRender implements IRenderEngine {

        public errorMessage = "";
        public targetCanvas: HTMLCanvasElement; 
        // public desktopCanvas = document.getElementById("desktopCanvas") as HTMLCanvasElement;
        public context: WebGLRenderingContext;
        public resolution = new Model.Resolution(0, 0);

        private positionLocation: number;
        private texCoordLocation: number;
        private program: WebGLProgram;

        private textureBuffer: WebGLBuffer;
        private texture: WebGLTexture;

        constructor(targetCanvas: HTMLCanvasElement = null) {
            if (targetCanvas != null) {
                this.targetCanvas = targetCanvas;
            }
            else {
                this.targetCanvas = document.createElement("canvas");
            }
        }

        private vertexShaderSource = `
                attribute vec2 a_position;
                attribute vec2 a_texCoord;

                uniform vec2 u_resolution;
                varying vec2 v_texCoord;

                void main() {

                    // convert the rectangle from pixels to 0.0 to 1.0
                    vec2 zeroToOne = a_position / u_resolution;

                    // convert from 0->1 to 0->2
                    vec2 zeroToTwo = zeroToOne * 2.0;

                    // convert from 0->2 to -1->+1 (clipspace)
                    vec2 clipSpace = zeroToTwo - 1.0;

                    gl_Position = vec4(clipSpace * vec2(1, -1), 0, 1);

                    // pass the texCoord to the fragment shader
                    // The GPU will interpolate this value between points.

                    v_texCoord = a_texCoord;
                }
        `;

        private fragmentShaderSource = `
                precision mediump float;
                // our texture
                uniform sampler2D u_image;

                // the texCoords passed in from the vertex shader.
                varying vec2 v_texCoord;

                void main() {
                    gl_FragColor = texture2D(u_image, v_texCoord);
                }
        `;

        public draw(model: Model.Render.RenderItemModel) {

            Debug.time("CanvasDrawing");

            // Upload the image into the texture.
            this.context.texImage2D(this.context.TEXTURE_2D, 0, this.context.RGB, this.context.RGB, this.context.UNSIGNED_BYTE, model.image as any);
            // this.context.texImage2D(this.context.TEXTURE_2D, 0, this.context.RGB, model.width, model.height, 0, this.context.RGB, this.context.UNSIGNED_BYTE, model.image as any);

            // Set the parameters so we can render any size image.
            this.context.texParameteri(this.context.TEXTURE_2D, this.context.TEXTURE_WRAP_S, this.context.CLAMP_TO_EDGE);
            this.context.texParameteri(this.context.TEXTURE_2D, this.context.TEXTURE_WRAP_T, this.context.CLAMP_TO_EDGE);
            this.context.texParameteri(this.context.TEXTURE_2D, this.context.TEXTURE_MIN_FILTER, this.context.NEAREST);
            this.context.texParameteri(this.context.TEXTURE_2D, this.context.TEXTURE_MAG_FILTER, this.context.NEAREST);


            // lookup uniforms
            let resolutionLocation = this.context.getUniformLocation(this.program, "u_resolution");

            // set the resolution
            this.context.uniform2f(resolutionLocation, this.targetCanvas.width, this.targetCanvas.height);

            // bind Buffer to use it.
            this.context.bindBuffer(this.context.ARRAY_BUFFER, this.textureBuffer);

            // Set PositionLocation
            this.context.enableVertexAttribArray(this.positionLocation);
            this.context.vertexAttribPointer(this.positionLocation, 2, this.context.FLOAT, false, 0, 0);


            // Set a rectangle the same size as the image.
            let x1 = model.posX;
            let x2 = model.posX2;
            let y1 = model.posY;
            let y2 = model.posY2;

            this.context.bufferData(this.context.ARRAY_BUFFER, new Float32Array([
                x1, y1,
                x2, y1,
                x1, y2,
                x1, y2,
                x2, y1,
                x2, y2]), this.context.STATIC_DRAW);

            // Draw the rectangle.
            this.context.drawArrays(this.context.TRIANGLE_STRIP, 0, 6);

            // Cleanup
            // this.context.deleteTexture(texture);
            this.context.bindFramebuffer(this.context.FRAMEBUFFER, null);
            
            Debug.timeEnd("CanvasDrawing");

        }

        public onresize(resolution: Model.Resolution) {
            this.resolution = resolution;
            this.targetCanvas.width = resolution.width;
            this.targetCanvas.height = resolution.height;

            // Set Viewport Size
            this.context.viewport(0, 0, resolution.width, resolution.height);
        }

        public init(): boolean {
            // this.context.globalCompositeOperation = "destination-over";
            
            try {

                // Create Context
                this.context = WebGL.create3DContext(this.targetCanvas);

                // Setup GLSL program
                let vertexShader = WebGL.loadShader(this.context, this.vertexShaderSource, this.context.VERTEX_SHADER);
                let fragmentShader = WebGL.loadShader(this.context, this.fragmentShaderSource, this.context.FRAGMENT_SHADER);

                // Check Shader
                if (vertexShader == null || fragmentShader == null) {
                    Debug.error("Failed to load shaders");
                    return false;
                }

                // Create Program
                this.program = this.context.createProgram();

                // Attach Shaders
                this.context.attachShader(this.program, vertexShader);
                this.context.attachShader(this.program, fragmentShader);

                // Link this.program to context
                this.context.linkProgram(this.program);

                // Check the link status
                if (!this.context.getProgramParameter(this.program, this.context.LINK_STATUS)) {

                    // something went wrong with the link
                    let error = this.context.getProgramInfoLog(this.program);
                    this.errorMessage = "Error in program linking:" + error;

                    // Delete Program
                    this.context.deleteProgram(this.program);
                    return false;

                }

                // Use Program 
                this.context.useProgram(this.program);

                // setup webGL stuff.
                return this.setupWebGL();


            } catch (error) {
                this.errorMessage = "Error in Init " + error;
                return false;
            }
        }

        private setupWebGL(): boolean {

            try {
                // Clear 
                this.context.clearColor(0.0, 0.0, 0.0, 1.0); // Clear to black, fully opaque
                this.context.clearDepth(1.0); // Clear everything
                this.context.clear(this.context.COLOR_BUFFER_BIT | this.context.DEPTH_BUFFER_BIT | this.context.STENCIL_BUFFER_BIT);

                this.context.disable(this.context.CULL_FACE);


                this.context.colorMask(true, true, true, false);
                // Blending options
                this.context.enable(this.context.BLEND);
                this.context.blendFunc(this.context.SRC_ALPHA, this.context.ONE_MINUS_SRC_ALPHA);

                // look up where the vertex data needs to go.
                this.positionLocation = this.context.getAttribLocation(this.program, "a_position");
                this.texCoordLocation = this.context.getAttribLocation(this.program, "a_texCoord");

                // provide texture coordinates for the rectancontexte.
                let texCoordBuffer = this.context.createBuffer();
                this.context.bindBuffer(this.context.ARRAY_BUFFER, texCoordBuffer);
                this.context.bufferData(this.context.ARRAY_BUFFER, new Float32Array([
                    0.0, 0.0,
                    1.0, 0.0,
                    0.0, 1.0,
                    0.0, 1.0,
                    1.0, 0.0,
                    1.0, 1.0]), this.context.STATIC_DRAW);

                this.context.enableVertexAttribArray(this.texCoordLocation);
                this.context.vertexAttribPointer(this.texCoordLocation, 2, this.context.FLOAT, false, 0, 0);

                // Set Viewport Size
                this.context.viewport(0, 0, window.innerWidth, window.innerHeight);

                // Create a buffer for the position of the rectangle corners.
                this.textureBuffer = this.context.createBuffer();

                // Create new texture
                this.texture = this.context.createTexture();

                this.context.activeTexture(this.context.TEXTURE0);
                this.context.bindTexture(this.context.TEXTURE_2D, this.texture);
                this.context.pixelStorei(this.context.UNPACK_ALIGNMENT, 1);
                // this.context.pixelStorei(this.context.UNPACK_FLIP_Y_WEBGL, 1);

                return true;

            } catch (error) {
                return false;
            }
        }

    }

}