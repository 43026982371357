/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="./GenericHeader.ts" />

namespace spacedesk.Model.Protocol.Packet.Header {

    export class VisibilityHeader extends GenericHeader {

        public visible: boolean;

        public GetBytes(): Uint8Array {
            return null;
        }

        public LoadFromBuffer(buffer: Uint8Array) {

            let visibleValue = buffer.getInteger32Value(8);
            this.visible = visibleValue === 1;
        }
    }
}