/// <reference path="../../../ProtocolDefinition.ts" />
/// <reference path="../../../../Resolution.ts" />
/// <reference path="../../../../Options.ts" />
/// <reference path="../../../../Rect.ts" />
/// <reference path="./GenericInputHeader.ts" />
/// <reference path="../../../ArrayExtensions.ts" />
/// <reference path="../../../../InputEnums.ts" />

namespace spacedesk.Model.Protocol.Packet.Header.KVM {

    export class DisconnectHeader extends GenericInputHeader {

        public type = ProtocolHeaderType.Disconnect;

        public GetBytes(): Uint8Array {

            const POSITION_TYPE = 0;

            let header = new Uint8Array(ProtocolDefinition.headerKVMLength);

            header.assignInteger32(POSITION_TYPE, this.type);

            return header;
        }
    }
}