/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../ViewController.ts" />
/// <reference path="../../Model/Debug.ts" />
/// <reference path="../../Model/InputEnums.ts" />
/// <reference path="../../Model/Point.ts" />
/// <reference path="../../Model/Rect.ts" />
/// <reference path="../../Model/Protocol/Packet/KVMPacket.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/GenericInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/KeyboardInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/MouseInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/TouchInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/DisconnectHeader.ts" />
/// <reference path="../../Model/Protocol/ProtocolDefinition.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/IdentificationHeader.ts" />


namespace spacedesk.Controller.KVM {

    import ProtocolDefinition = spacedesk.Model.Protocol.ProtocolDefinition;
    import Packet = spacedesk.Model.Protocol.Packet;
    import KVM = spacedesk.Model.Protocol.Packet.Header.KVM;
    import KeyState = spacedesk.Model.KeyState;
    import MouseButton = spacedesk.Model.MouseButton;
    import MouseState = spacedesk.Model.MouseState;
    import Point = spacedesk.Model.Point;

    export abstract class KVMBaseController {

        protected lastPosition = new Point();

        private spacedeskController : SpacedeskController;

        public get connected(): boolean {
            return this.spacedeskController.Connected;
        }

        constructor(sc: SpacedeskController) {

            this.spacedeskController = sc;

            // Register Events
            this.spacedeskController.ConnectionEstablished.on(() => this.connectionEstablished());
            this.spacedeskController.Disconnected.on(() => this.removeEventListener());

        }

        public connectToServer(settings: Model.Options) {
            this.spacedeskController.connectToServer(settings);
        }

        public closeConnection() {
            
            let header = new KVM.DisconnectHeader();
            let packet = new Packet.KVMPacket(header);
            let data = packet.GetBufferData();
            
            this.spacedeskController.closeConnection(data);
        }

        protected abstract addEventListener(): void;

        protected abstract removeEventListener(): void;

        protected sendMouse(button: MouseButton = MouseButton.None, state: MouseState = MouseState.Move, position: Point = null, wheelDelta: number = -1) {

            if (!this.spacedeskController.Connected) {
                return;
            }

            if(!Model.Options.currentOptions.kvmControl) {
                return;
            }

            let header = new KVM.MouseInputHeader();
            header.button = button;
            header.state = state;

            if (wheelDelta > 0) {
                header.wheelDelta = wheelDelta;
            }

            header.position = position;

            let packet = new Packet.KVMPacket(header);
            let data = packet.GetBufferData();

            // send data
            this.spacedeskController.sendData(data);

            // cleanup
            data = null;
            packet.dispose();


            return;
        }

        protected sendTouch(touchState : spacedesk.Model.Protocol.TouchState, position : Point) {
            
            if (!this.spacedeskController.Connected) {
                return;
            }

            if(!Model.Options.currentOptions.kvmControl) {
                return;
            }

            let header = new spacedesk.Model.Protocol.Packet.Header.KVM.TouchInputHeader();
            header.touchState = touchState;
            header.position = position;
            header.resX = ViewController.Canvas.clientWidth;
            header.resY = ViewController.Canvas.clientHeight;

            let packet = new Packet.KVMPacket(header);

            let data = packet.GetBufferData();

            if (Debug.kvmProtocol) {
                Debug.info("TouchState: " + touchState + " Coords: x -" + header.position.x + " y -" + header.position.y);
            } 

            // send data
            this.spacedeskController.sendData(data);

            // cleanup
            data = null;
            packet.dispose();
        }

        protected sendKey(state: KeyState, keyCode: number) {

            if (!this.spacedeskController.Connected) {
                return;
            }
            
            if (Debug.kvmProtocol) {
                Debug.info("Key: " + String.fromCharCode(keyCode) + " (" + KeyState[state] + ")");
            }

            let header = new KVM.KeyboardInputHeader();
            header.keyCode = keyCode;
            header.state = state;

            let packet = new Packet.KVMPacket(header);

            // send data
            let data = packet.GetBufferData();
            this.spacedeskController.sendData(data);

            // cleanup
            data = null;
            packet.dispose();

            return;
        }

        protected keyDownEvent(event: KeyboardEvent) {
            event.preventDefault();
            if (event.keyCode > 0) {
                this.sendKey(KeyState.Down, event.keyCode);
            }
        }

        protected keyUpEvent(event: KeyboardEvent) {
            event.preventDefault();
            if (event.keyCode > 0) {
                this.sendKey(KeyState.Up, event.keyCode);
            }
        }

        private connectionEstablished() {

            // due to 4.7 protocol
            // let identification = new Model.Protocol.Packet.IdentificationPacket(Model.Options.currentOptions, Model.Protocol.ProtocolClientType.KvmWindows);
            // let data = identification.GetBufferData();
            // this.spacedeskController.sendData(data);

            this.addEventListener();
            // Controller.UI.AnimationController.showDesktop();
        }
    }

}