/// <reference path="../Model/Utils.ts" />
/// <reference path="../Model/Events.ts" />
/// <reference path="./SpacedeskController.ts" />
/// <reference path="./ViewController.ts" />
/// <reference path="./ImageController.ts" />
/// <reference path="./DesktopController.ts" />
/// <reference path="./KVM/KVMBaseController.ts" />
/// <reference path="./KVM/KVMFactory.ts" />
/// <reference path="../Model/ConnectionState.ts" />

/// <reference path="../Model/Protocol/Packet/FrameBufferPacket.ts" />
/// <reference path="../Model/Protocol/Packet/Header/VisibilityHeader.ts" />

namespace spacedesk.Controller {

    import Models = spacedesk.Model;

    export class ConnectionController {


        private spacedeskController = new SpacedeskController();
        private viewController = new Controller.ViewController();
        private kvmController: KVM.KVMBaseController;

        private state = Model.ConnectionState.NotConnected;
        private lastErrorCode = "";

        private onStateChanged = new SpacedeskEvent<Model.ConnectionState>();

        public get connectionState() {
            return this.state;
        }

        public get errorCode() {
            return this.lastErrorCode;
        }

        public set connectionState(newState: Model.ConnectionState) {
            this.state = newState;
            this.onStateChanged.trigger(this.state);
        }


        public disconnect() {

            UI.PointerLockController.exitPointerLock();

            this.spacedeskController.closeConnection();

        }

        public connectToServer(server: string): boolean {

            Model.Options.currentOptions.server = server;

            return this.connect(Model.Options.currentOptions);
        }

        public connect(options: Model.Options): boolean {
            if (this.state === Model.ConnectionState.NotConnected) {
                this.connectionState = Model.ConnectionState.Connecting;
                this.spacedeskController.connectToServer(options);

                this.viewController.stretchScreen = options.stretchScreen;

                return true;
            }

            return false;
        }

        public sendAck(packet: Uint8Array) {
            if(this.state = Model.ConnectionState.Connected ) {
                this.spacedeskController.sendData(packet);
            }
        }

        public init(): boolean {

            this.spacedeskController.PacketReceived.on((data?) => { this.PacketReceived(data); });

            this.spacedeskController.Reconnect.on(() => {
                this.connectionState = Model.ConnectionState.Reconnecting;
            });
            this.spacedeskController.ConnectionError.on((errorCode) => {
                this.lastErrorCode = errorCode;
                this.connectionState = Model.ConnectionState.Error;
            });
            this.spacedeskController.Timeout.on(() => {
                this.lastErrorCode = "Connection Timeout. Make sure your Server-IP is correct.";
                this.connectionState = Model.ConnectionState.Error;
            });
            this.spacedeskController.Disconnected.on(() => {
                this.connectionState = Model.ConnectionState.NotConnected;
            });
            this.spacedeskController.ConnectionEstablished.on(() => {

                let identification = new Model.Protocol.Packet.IdentificationPacket(Model.Options.currentOptions);
                let data = identification.GetBufferData();
                this.spacedeskController.sendData(data);

                this.connectionState = Model.ConnectionState.Connected;

                // if (Model.Options.currentOptions.kvmControl) {
                //    this.kvmController.connectToServer(Model.Options.currentOptions);
                // }

                // spacedesk.Controller.UI.AnimationController.showDesktop();
                // To Do
            });


            if (!this.viewController.init(this)) {
                return false;
            }

            this.kvmController = Controller.KVM.KVMFactory.Build(this.spacedeskController);
            
            return true;
        }

        public get StateChanged(): ISpacedeskEvent<(Model.ConnectionState)> { return this.onStateChanged; }

        private PacketReceived(data: Model.Protocol.Packet.SpacedeskPacket) {
            switch (data.header.identification) {
                case Model.Protocol.ProtocolHeaderType.FrameBuffer:

                    this.viewController.pushFrameBuffer(data as Model.Protocol.Packet.FrameBufferPacket);

                    break;

                case Model.Protocol.ProtocolHeaderType.Visibility:

                    // set Visibility
                    this.viewController.setVisibility((data.header as Model.Protocol.Packet.Header.VisibilityHeader).visible);

                    break;

                case Model.Protocol.ProtocolHeaderType.CursorBitmap:

                    this.viewController.drawCursor(data as Model.Protocol.Packet.CursorBitmapPacket);


                    break;

                case Model.Protocol.ProtocolHeaderType.CursorPosition:

                    this.viewController.setCursorPosition(data as Model.Protocol.Packet.CursorPositionPacket);

                    break;

                default:
                    break;
            }


        }

    }

}