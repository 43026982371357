/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="./SpacedeskPacket.ts" />
/// <reference path="./Header/FrameBufferHeader.ts" />


namespace spacedesk.Model.Protocol.Packet {

    export class FrameBufferPacket extends SpacedeskPacket {

        public header: Header.FrameBufferHeader;

    }
}