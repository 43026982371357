/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="./SpacedeskPacket.ts" />
/// <reference path="./Header/CursorBitmapHeader.ts" />

namespace spacedesk.Model.Protocol.Packet {

    export class CursorBitmapPacket extends SpacedeskPacket {

        public header: Header.CursorBitmapHeader;

    }
}