
/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="./GenericHeader.ts" />
/// <reference path="./FrameBufferHeader.ts" />
/// <reference path="./VisibilityHeader.ts" />
/// <reference path="./CursorPositionHeader.ts" />
/// <reference path="./CursorBitmapHeader.ts" />

namespace spacedesk.Model.Protocol.Packet.Header {

    export class HeaderFactory {

        public static GetHeader(buffer: ArrayBuffer): GenericHeader {

            let header = new Uint8Array(buffer, 0, ProtocolDefinition.headerLength);
            let headerType = header.getInteger32Value(0);

            switch (headerType) {
                case ProtocolHeaderType.FrameBuffer:
                    return new FrameBufferHeader(header);

                case ProtocolHeaderType.Visibility:
                    return new VisibilityHeader(header);

                case ProtocolHeaderType.CursorPosition:
                    return new CursorPositionHeader(header);

                case ProtocolHeaderType.CursorBitmap:
                    return new CursorBitmapHeader(header);

                default:

                    break;
            }

            return null;
        }

    }
}