/// <reference path="../../../ProtocolDefinition.ts" />
/// <reference path="../../../../Resolution.ts" />
/// <reference path="../../../../Options.ts" />
/// <reference path="../../../../Rect.ts" />
/// <reference path="./GenericInputHeader.ts" />
/// <reference path="../../../ArrayExtensions.ts" />
/// <reference path="../../../../InputEnums.ts" />

namespace spacedesk.Model.Protocol.Packet.Header.KVM {

    export class KeyboardInputHeader extends GenericInputHeader {

        public type = ProtocolHeaderType.Keyboard;

        public keyCode: number;

        public state: KeyState;

        public GetBytes(): Uint8Array {

            const POSITION_TYPE = 0;
            const POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER = 4;
            const POSITION_VKEYCODE = 8;
            const POSITION_SCANCODE = 12;
            const POSITION_FLAGS = 16;
            const POSITION_TIME = 20;
            const POSITION_RESERVED = 24;

            let header = new Uint8Array(ProtocolDefinition.headerLength);
            let flags = 0x0;

            header.assignInteger32(POSITION_TYPE, this.type);
            header.assignInteger32(POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER, 0);

            header.assignInteger32(POSITION_VKEYCODE, this.keyCode);

            header.assignInteger32(POSITION_SCANCODE, this.keyCode);
            flags |= KeyEvents.ScanCode;

            if (this.state === KeyState.Up) {
                flags |= KeyEvents.KeyUp;
            }

            header.assignInteger32(POSITION_FLAGS, flags);
            header.assignInteger32(POSITION_TIME, 0);
            header.assignInteger32(POSITION_RESERVED, 0);

            return header;
        }
    }
}