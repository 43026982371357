/// <reference path="../../Model/Utils.ts" />
/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../../Model/Options.ts" />
/// <reference path="../../Controller/ViewController.ts" />
/// <reference path="./PointerLockController.ts" />


namespace spacedesk.Controller.UI {

    import ViewController = Controller.ViewController;

    export class FullscreenController {

        
        public static registerEvents() {
            $(".togglefullscreen").toggle(FullscreenController.fullscreenEnabled);

            if (FullscreenController.fullscreenEnabled) {
                $(document).on("webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange", (e) => {
                    let fullscreen = Controller.UI.FullscreenController.isFullscreen;

                    const icon_fullscreen_exit = "<!--fullscreen_exit-->&#xE5D1;";
                    const icon_fullscreen = "<!--fullscreen-->&#xE5D0;";

                    $(".togglefullscreen .material-icons").html(fullscreen ? icon_fullscreen_exit : icon_fullscreen);
                    $("#menu .togglefullscreen").html(fullscreen ? "<i class='material-icons'>" + icon_fullscreen_exit + "</i>Leave Fullscreen</a>" : "<i class='material-icons'>" + icon_fullscreen + "</i>Enter Fullscreen</a>");
                });
            }
        }

        public static get fullscreenEnabled(): boolean {
            return document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                (document as any).mozFullScreenEnabled ||
                (document as any).msFullscreenEnabled;
        }

        public static get isFullscreen(): boolean {
            if (document.fullscreenElement) {
                return document.fullscreenElement != null;
            }
            if (document.webkitFullscreenElement) {
                return document.webkitFullscreenElement != null;
            }
            if ((document as any).mozFullScreenElement) {
                return (document as any).mozFullScreenElement != null;
            }
            if ((document as any).msFullscreenElement) {
                return (document as any).msFullscreenElement != null;
            }
            return false;
        }

        public static requestFullscreen(setOptions = true) {
            let elem = document.body;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
            else if ((elem as any).mozRequestFullScreen) {
                (elem as any).mozRequestFullScreen();
            }
            else if ((elem as any).msRequestFullscreen) {
                (elem as any).msRequestFullscreen();
            }

            if (setOptions) {
                Model.Options.currentOptions.fullscreen = true;
            }

            if (Model.Options.currentOptions.kvmControl) {
                PointerLockController.requestPointerLock();
            }
        }

        public static cancelFullscreen(setOptions = true) {

            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            else if ((document as any).mozCancelFullScreen) {
                (document as any).mozCancelFullScreen();
            }
            else if ((document as any).msExitFullscreen) {
                (document as any).msExitFullscreen();
            }

            if (setOptions) {
                Model.Options.currentOptions.fullscreen = false;
            }
        }

        public static toggleFullscreen(setOptions = true) {

            if (FullscreenController.isFullscreen) {
                FullscreenController.cancelFullscreen(setOptions);
            }
            else {
                FullscreenController.requestFullscreen(setOptions);
            }

        }

    }
}