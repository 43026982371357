
enum ShaderTypes {
    Vertex = WebGLRenderingContext.VERTEX_SHADER,
    Fragment = WebGLRenderingContext.FRAGMENT_SHADER,
}


namespace spacedesk.Model.Render {


    export class WebGLHelper {

        public static CheckForWebGL(): boolean {

            let canvas = document.createElement("canvas");

            let context = this.create3DContext(canvas);
            if (context != null) {

                canvas = null;
                context = null;
                return true;
            }

            canvas = null;
            return false;
        }

        public static create3DContext(canvas: HTMLCanvasElement): WebGLRenderingContext {

            let names = ["webgl", "experimental-webgl"];
            let options = {
                alpha: false,
                antialias: false,
                depth: false,
                // premultipliedAlpha: false,
                preserveDrawingBuffer: true,
            };

            if (Utils.isIE) {
                options.preserveDrawingBuffer = true;
            }

            let context: WebGLRenderingContext;
            names.forEach(name => {
                try {
                    // Try to create WebGL Context
                    context = canvas.getContext(name, options) as WebGLRenderingContext;
                }
                catch (e) { 
                    // Log
                }

                if (context != null) {
                    return true;
                }
            });

            return context;
        }

        public static loadShader(context: WebGLRenderingContext, shaderSource: string, shaderType: number) {
        
            // Create the shader object
            let shader = context.createShader(shaderType);

            // Load the shader source
            context.shaderSource(shader, shaderSource);

            // Compile the shader
            context.compileShader(shader);

            // Check the compile status
            let compiled = context.getShaderParameter(shader, context.COMPILE_STATUS);

            if (!compiled) {
                // Something went wrong during compilation; get the error
                context.deleteShader(shader);
                return null;
            }

            return shader;
        }


    }
}