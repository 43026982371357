/// <reference path="../../Model/Utils.ts" />
/// <reference path="../../../tools/typings/tsd.d.ts" />

namespace spacedesk.Controller.UI {

    export class HelpController {

        private static toggleInEffect = "fadeIn";
        private static toggleOutEffect = "fadeOut";

        private static stepInEffect = "bounceInRight";
        private static stepOutEffect = "bounceOutLeft";

        public static hideHelp() {
            
            let $help = $("#help");
            
            $help.removeClass(this.toggleInEffect).removeClass(this.toggleOutEffect);
            $help.hide();
        }

        public static toggleHelp(visible = true, animated = true) {
            let $inputs = $("#form-input");
            let $help = $("#help");

            
            if (visible && $help.hasClass(this.toggleInEffect)) {
                return;
            }

            
            $inputs.removeClass(this.toggleInEffect).removeClass(this.toggleOutEffect);
            $help.removeClass(this.toggleInEffect).removeClass(this.toggleOutEffect);
            
            

            if (animated) {

                if (visible) {

                    $("#help section").removeClass(this.stepInEffect).removeClass(this.stepOutEffect).removeClass("active");

                    let $firstSection = $("#help section:first");
                    $firstSection.addClass("active");

                    $("#help .close").toggle($firstSection.data("closeable"));

                    $inputs.addClass(this.toggleOutEffect);
                }
                else {
                    $help.addClass(this.toggleOutEffect);
                }

                setTimeout(() => {
                    if (visible) {
                        $inputs.hide();
                        $help.show().addClass(this.toggleInEffect);
                    }
                    else {
                        $help.hide();
                        $inputs.show().addClass(this.toggleInEffect);

                        setTimeout(() => {
                            $("#server").focus().click();
                        }, 400);

                    }
                }, 600);
            }
            else {
                if (visible) {
                    $inputs.hide();
                    $help.show();
                }
                else {
                    $help.hide();
                    $inputs.show();
                }
            }
        }


        private registerEvents() {

            $(document).on("click", "#help-me", () => HelpController.toggleHelp(true));
            $(document).on("click", "#help .close", () => HelpController.toggleHelp(false));

            $(document).on("click", "#help a.input-button-large", (e: JQueryEventObject) => this.nextStep(e));

        }

        private nextStep(e: JQueryEventObject) {
            e.preventDefault();

            let target = $(e.target).attr("href");

            if (target === "#close") {
                HelpController.toggleHelp(false);
                return;
            }

            let $current = $("#help section.active");
            let $next = $current.next(target);

            $current.removeClass(HelpController.stepInEffect).removeClass(HelpController.stepOutEffect);
            $next.removeClass(HelpController.stepInEffect).removeClass(HelpController.stepOutEffect);

            $current.addClass(HelpController.stepOutEffect);

            setTimeout(() => {
                $current.removeClass("active");
                $next.addClass(HelpController.stepInEffect).addClass("active");
            }, 500);

            $("#help .close").toggle($next.data("closeable"));
        }

        constructor() {
            this.registerEvents();
        }

    }
}