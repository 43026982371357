/// <reference path="../../tools/typings/tsd.d.ts" />
/// <reference path="../Model/Render/WebGLHelper.ts" />
/// <reference path="../Model/Events.ts" />
/// <reference path="./spacedeskController.ts" />
/// <reference path="./ImageController.ts" />
/// <reference path="../Model/Protocol/Packet/FrameBufferPacket.ts" />
/// <reference path="./RenderEngine/IRenderEngine.ts" />
/// <reference path="./RenderEngine/WebGLRender.ts" />
/// <reference path="./RenderEngine/CanvasRender.ts" />
/// <reference path="./DiagnosticController.ts" />

namespace spacedesk.Controller {

    import Packet = spacedesk.Model.Protocol.Packet;
    import WebGL = spacedesk.Model.Render.WebGLHelper;
    import RenderItemModel = spacedesk.Model.Render.RenderItemModel;
    import RenderEngine = spacedesk.Controller.RenderEngine;

    interface Array<T> {
        set(array: ArrayLike<number>, offset?: number): void;
    }


    export class DesktopController {

        private imageController = new Controller.ImageController();
        private connectionController : Controller.ConnectionController;
        private renderEngine: RenderEngine.IRenderEngine;
        private fragmentCounter = 0;
        private isBusy = false;
        private ackPacket = new Packet.FlowControlAckPacket();

        // backBufferCanvas is only used when the tabs get inactive.
        // When the tab gets inactive (hidden) the WebGL Canvas turns transparent, so there's no desktop any more.
        // As Fallback when the tab gets hidden copy webgl image in backBufferCanvas
        // Before the next onDesktopDrawed is triggered remove the backBufferCanvas and use the old webgl state again, which surprisingly works...
        private backBufferCanvas: HTMLCanvasElement;


        private onDesktopDrawed = new SpacedeskEvent<HTMLCanvasElement>();

        public get DesktopCanvas(): HTMLCanvasElement {
            return this.renderEngine.targetCanvas;
        }

        public get DesktopDrawed(): ISpacedeskEvent<HTMLCanvasElement> { return this.onDesktopDrawed; }

        public drawDesktopOn(renderEngine: RenderEngine.IRenderEngine) {

            let model = new RenderItemModel();
            model.posX = 0;
            model.posX2 = this.renderEngine.resolution.width;
            model.posY = 0;
            model.posY2 = this.renderEngine.resolution.height;

            // should use fallback Canvas? --> see comment above declaration
            if (this.backBufferCanvas !== undefined) {
                model.image = this.backBufferCanvas;

                // context.drawImage(this.backBufferCanvas, 0, 0, this.renderEngine.resolution.width, this.renderEngine.resolution.height, 0, 0, this.renderEngine.resolution.width, this.renderEngine.resolution.height);
            }
            else {
                // too slow
                // this.copyDesktopOn(context);
                model.image = this.renderEngine.targetCanvas;
                
                // context.drawImage(this.renderEngine.targetCanvas, 0, 0, this.renderEngine.resolution.width, this.renderEngine.resolution.height, 0, 0, this.renderEngine.resolution.width, this.renderEngine.resolution.height);
            }
            
            renderEngine.draw(model);
        }


        public init(cc: ConnectionController): boolean {

            // Register Events
            this.imageController.ImageReady.on(() => {
                // when Image loaded
                this.onImageReady();
            });

            this.registerVisibilityChangeEvents();

            this.connectionController = cc;

            // this.renderEngine = new RenderEngine.CanvasRender();

            // Check WebGL 
            if (WebGL.CheckForWebGL()) {
                // this.renderEngine = new RenderEngine.WebGLRender(ViewController.Canvas);
                this.renderEngine = new RenderEngine.WebGLRender();
            }
            else {
                this.renderEngine = new RenderEngine.CanvasRender();
            }


            // If engine created
            if (this.renderEngine != null) {

                // Init Engine
                return this.renderEngine.init();
            }
            // Damn...
            return false;
        }

        public pushFrameBuffer(data: Packet.FrameBufferPacket) {
            // Add Model to Stack
            this.imageController.push(data);

            // this.imageController.renderImage(data);
        }

        public resizeDesktop(resolution: Model.Resolution) {

            this.renderEngine.onresize(resolution);
        }

        private onImageReady() {

            // Check if is Busy
            if (this.isBusy) {
                return;
            }
            
            // Set flag to busy
            this.isBusy = true;
            
            Debug.log("Start Queue: " + this.imageController.imageReadyQueue.length);

            while (this.imageController.imageReadyQueue.length > 0) {
                
                // Get first Image of queue
                let model = this.imageController.imageReadyQueue.shift();


                DiagnosticController.start(DiagnosticTimeTypes.render);

                // Paint it on Canvas
                this.renderEngine.draw(model);

                DiagnosticController.end(DiagnosticTimeTypes.render);

                // If is image fragment
                if (model.isFragement) {

                    // count up
                    this.fragmentCounter++;

                    // if counter modulo 4 unequal 0
                    if (this.fragmentCounter % 4 !== 0) {
                        this.imageController.cleanUpModel(model);
                        
                        // Check how many Fragments needed
                        let moreFragmentNeeded = 4 - this.fragmentCounter;

                        // If the needed fragments are in the queue?
                        if (this.imageController.imageReadyQueue.length >= moreFragmentNeeded) {

                            // Wait for following images
                            continue;
                        }
                        else {
                            Debug.log("End Queue (Fragment not available)");
                            // Images are not available at the moment, wait for more 
                            this.isBusy = false;
                            return;
                        }
                    }
                    else {
                        // Reset Counter
                        this.fragmentCounter = 0;
                    }
                }
                else {
                    // Reset Counter
                    this.fragmentCounter = 0;
                }

                this.imageController.cleanUpModel(model);
                this.backBufferCanvas = undefined;

                Debug.log("Draw");

                // Trigger Event
                this.onDesktopDrawed.trigger(this.renderEngine.targetCanvas);
                this.connectionController.sendAck(this.ackPacket.GetBufferData());
                Debug.log("ACK sent");
            }


            Debug.log("End Queue");

            this.isBusy = false;
            
        }

        private registerVisibilityChangeEvents() {

            let hidden = "";
            let visibilityChange = "";
            if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
                hidden = "hidden";
                visibilityChange = "visibilitychange";
            } else if (typeof (document as any).mozHidden !== "undefined") {
                hidden = "mozHidden";
                visibilityChange = "mozvisibilitychange";
            } else if (typeof document.msHidden !== "undefined") {
                hidden = "msHidden";
                visibilityChange = "msvisibilitychange";
            } else if (typeof (document as any).webkitHidden !== "undefined") {
                hidden = "webkitHidden";
                visibilityChange = "webkitvisibilitychange";
            }

            document.addEventListener(visibilityChange, () => {
                if ((document as any)[hidden]) {
                    this.copyDesktopToBackCanvas();
                }
            }, false);
            document.addEventListener("blur", () => {
                if ((document as any)[hidden]) {
                    this.copyDesktopToBackCanvas();
                }
            }, false);
            window.addEventListener("blur", () => {
                if ((document as any)[hidden]) {
                    this.copyDesktopToBackCanvas();
                }
            }, false);

        }

        private copyDesktopToBackCanvas() {

            this.backBufferCanvas = document.createElement("canvas");
            let context = this.backBufferCanvas.getContext("2d");

            let width = this.renderEngine.resolution.width;
            let height = this.renderEngine.resolution.height;

            this.backBufferCanvas.width = width;
            this.backBufferCanvas.height = height;

            this.copyDesktopOn(context);
        }

        private copyDesktopOn(context: CanvasRenderingContext2D) {

            let width = this.renderEngine.resolution.width;
            let height = this.renderEngine.resolution.height;

            // Width * Height * 4 (RGBA)
            let pixels = new Uint8Array(width * height * 4);

            // create new ImageData
            let image = context.createImageData(width, height);

            // Get WebGL-Context
            let gl = this.renderEngine.context as WebGLRenderingContext;

            // Read all Pixels from WebGL Context
            gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

            // flip Y            
            let dst = image.data;
            let d = pixels;
            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    let off = (y * width + x) * 4;
                    let dstOff = ((height - y - 1) * width + x) * 4;
                    dst[dstOff] = d[off];
                    dst[dstOff + 1] = d[off + 1];
                    dst[dstOff + 2] = d[off + 2];
                    dst[dstOff + 3] = d[off + 3];
                }
            }

            // Put imageData back to canvas
            context.putImageData(image, 0, 0);
        }

    }
}
