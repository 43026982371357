/// <reference path="../Model/Utils.ts" />
/// <reference path="../../tools/typings/tsd.d.ts" />
/// <reference path="./ConnectionController.ts" />
/// <reference path="./DiagnosticController.ts" />
/// <reference path="./BroadcastController.ts" />
/// <reference path="./UI/HelpController.ts" />
/// <reference path="./UI/AnimationController.ts" />
/// <reference path="./UI/SettingsController.ts" />
/// <reference path="./UI/MenuController.ts" />
/// <reference path="./UI/LoaderController.ts" />
/// <reference path="./UI/OrientationController.ts" />
/// <reference path="./UI/FullscreenController.ts" />
/// <reference path="./UI/PointerLockController.ts" />


namespace spacedesk.Controller {

    import FullscreenController = UI.FullscreenController;
    import PointerLockController = UI.PointerLockController;

    export class AppController {

        private connection = new Controller.ConnectionController();
        private help = new Controller.UI.HelpController();
        private settings: Controller.UI.SettingsController;
        private menu = new Controller.UI.MenuController();

        private shouldReconnectToServer = false;

        public init() {

            if (!this.checkCompatibility()) {
                // this.showError();
                return;
            }

            Controller.UI.LoaderController.registerLoader();

            this.settings = new Controller.UI.SettingsController(Model.Options.currentOptions);

            this.setRetinaImages();

            this.registerEvents();

            // Model.Options.currentOptions.resolutionCustom = Model.Resolution.GetFullScreenResolution();

            if (this.connection.init()) {

                this.connection.StateChanged.on((newState) => {
                    switch (newState) {
                        case Model.ConnectionState.NotConnected:
                            this.onDisconnected();
                            break;

                        case Model.ConnectionState.Connected:
                            this.onConnected();
                            break;

                        case Model.ConnectionState.Error:
                            FullscreenController.cancelFullscreen(false);

                            UI.AnimationController.showErrorState("<i class='material-icons md-48' ><!--warning-->&#xE002;</i> Could not connect.<br/> " + this.connection.errorCode);
                            $("#connectionStatus").text("Not connected");
                            break;

                        default:
                            break;
                    }
                });

                if (Model.Options.currentOptions.server != null && Model.Options.currentOptions.server !== "") {

                    $("#server").val(Model.Options.currentOptions.server);

                    if (Model.Options.currentOptions.autoConnect || Model.Options.currentOptions.queryConnect) {
                        this.connect();
                    }
                }
            }
        }

        /**
         * Add a spacedesk Server to the list
         * 
         * @param {string} server ip address
         */
        public addBroadcastServer(server: string) {
            BroadcastController.addBroadcastServer(server);
        }

        /**
         * Register Broadcast lookup.
         * Hides the input and replace it with an spinner
         */
        public registerBroadcastLookup() {
            BroadcastController.registerBroadcastLookup();
        }

        /**
         * Hides the broadcast lookup spinner
         */
        public unregisterBroadcastLookup() {
            BroadcastController.unregisterBroadcastLookup();
        }

        /**
         * Check for Websockets and Canvas Objects
         * 
         * @private
         * @returns {boolean} return true if available
         */
        private checkCompatibility(): boolean {
            return Modernizr.websockets && Modernizr.canvas;
        }

        private registerEvents() {

            this.registerModalEvents();

            // Wondering why "this." not working on click events. Although it's correct lambda
            let app = this;

            window.onbeforeunload = (e) => {
                app.disconnect();
            };

            $(document).on("click touchend", "#buttonLogin", (e) => {
                e.preventDefault();
                this.connectUsingInput();
            });

            $("#server").keypress((e) => {
                if (e.which === 13) {
                    e.preventDefault();
                    this.connectUsingInput();
                    return false;
                }
            });



            $("#enterself").on("click touchend", (e) => {
                $("#input").show();
                $("#broadcast").hide();
            });

            $(document).on("click touchend", "#broadcastlist a", (e) => {
                e.preventDefault();

                let server = $(e.target).data("server");
                if (typeof server !== "undefined") {

                    $("#server").val(server);

                    $("#input").show();
                    $("#broadcast").hide();
                    this.connectUsingInput();
                }
            });

            $("input:text").focus(function () {
                $(this).select();
                (this as HTMLInputElement).selectionStart = 0;
                (this as HTMLInputElement).selectionEnd = (this as HTMLInputElement).value.length + 1;
            });

            $(".reconnect").on("click touchend", (e) => {
                $("#modalOrientationChanged").prop("checked", false).change();
                app.settings.hideSettingsReloadModal();
                app.reconnectToServer();
            });
            $(".noreconnect").on("click touchend", (e) => {
                app.settings.hideSettingsReloadModal();

                $("#modalOrientationChanged").prop("checked", false).change();
                if (app.connection.connectionState !== Model.ConnectionState.NotConnected) {
                    UI.AnimationController.showDesktop();
                }
            });
            $(".closeConnection").on("click touchend", (e) => {
                app.disconnect();
                Model.Options.currentOptions.autoConnect = false;
            });
            $(".togglefullscreen").on("click touchend", (e) => {
                FullscreenController.toggleFullscreen(true);
            });
            $(".togglekvm").on("click touchend", (e) => {
                PointerLockController.requestPointerLock();
            });
            $(".reloadPage").on("click touchend", (e) => {
                location.reload();
            });
            $(".showKeyboard").on("click touchend", (e) => {
                $("#keyboard").focus();
            });
            $("#generateStaticLink").on("click touchend", (e) => {
                $("#modalStaticLink").prop("checked", true).change();
            });
            $("#staticUrl").on("click touchend", (e) => {
                $("#staticUrl").select();
            });
            $("#clearSettings").on("click touchend", (e) => {
                Model.Options.currentOptions.clearOptions();
                location.reload();
            });


 
            $("#center-stage").on("dblclick", (e) => {
                if (FullscreenController.fullscreenEnabled && !PointerLockController.pointerLockEnabled) {
                    FullscreenController.toggleFullscreen(true);
                    window.getSelection().empty();
                }
            });

            $(document).on("click touchend", ".showsettings", (e) => {
                e.preventDefault();
                if (app.connection.connectionState !== Model.ConnectionState.NotConnected) {

                    UI.AnimationController.showStart(false);
                    app.settings.showSettings(false);
                }
                else {
                    app.settings.showSettings();
                }
            });

            $(document).on("click touchend", ".applysettings", (e) => {
                e.preventDefault();

                let idle = app.connection.connectionState === Model.ConnectionState.NotConnected;

                if (app.settings.ChangedSettings) {

                    Model.Options.currentOptions.saveOptions();

                    app.settings.hideSettings(idle, idle);

                    if (!idle) {
                        app.settings.showSettingsReloadModal();
                    }
                }
                else {
                    if (!idle) {
                        UI.AnimationController.showDesktop();
                    }

                    setTimeout(() => {
                        app.settings.hideSettings(idle);
                    }, idle ? 0 : 1000);
                }
            });

            $(document).on("click touchend", ".abortsettings", (e) => {
                e.preventDefault();

                let idle = app.connection.connectionState === Model.ConnectionState.NotConnected;

                app.settings.restoreSettings();

                if (!idle) {
                    UI.AnimationController.showDesktop();
                }

                setTimeout(() => {
                    app.settings.hideSettings(idle);
                }, idle ? 0 : 1000);
            });


            UI.FullscreenController.registerEvents();
            UI.OrientationController.registerEvents();


            window.addEventListener("orientationchange", () => {
                if (app.connection.connectionState !== Model.ConnectionState.NotConnected) {
                    $("#modalOrientationChanged").prop("checked", true).change();
                }
            }, false);

            $("body").addClass(spacedesk.Utils.browser.os.group.replace(/\s+/g, "").toLowerCase());
            if (Utils.isDesktopDevice) {
                $("body").addClass("desktop");
            }

            if (Utils.browser.browser.group === "Explorer") {
                $("body").addClass("ie");

                if (Utils.browser.browser.majorVersion < 9) {
                    $("body").addClass("notsupported");
                }
            }
            if (Utils.isEdge) {
                $("body").addClass("notsupported");
                $(".errorText").html("<p>Microsoft Edge Browser is temporarily not supported.</p>");
            }
        }

        private registerModalEvents() {
            $(function () {
                $(".modal-state").on("change", function () {
                    if ($(this).is(":checked")) {
                        $(this).parent().addClass("modal-open");
                    } else {
                        $(this).parent().removeClass("modal-open");
                    }
                });

                $(".modal-close").on("click", function () {
                    $(".modal-state:checked").prop("checked", false).change();
                });

                $(".modal-inner").on("click", function (e) {
                    e.stopPropagation();
                });
            });
        }

        private connectUsingInput() {

            let server = $("#server").val();

            if (server != null && server.length > 0) {
                Model.Options.currentOptions.server = server;

                this.connect();
            }
        }

        private connect() {

            if (this.connection.connectionState === Model.ConnectionState.NotConnected) {

                $("input").blur();
                window.focus();
                $("#buttonLogin").focus();

                // Put this as near as possible to the click Event, otherwise Firefox will block the fullscreen request.
                if (Model.Options.currentOptions.fullscreen) {
                    FullscreenController.requestFullscreen();
                }

                spacedesk.Controller.UI.AnimationController.showConnect();

                Model.Options.currentOptions.saveOptions();

                setTimeout(() => {
                    this.connection.connect(Model.Options.currentOptions);
                }, 500);
            }

        }

        private disconnect() {

            document.title = "spacedesk HTML5 VIEWER";
            this.connection.disconnect();
            this.settings.hideSettings(false);

            Model.Options.currentOptions.saveOptions();
            FullscreenController.cancelFullscreen(false);
            UI.OrientationController.resetZoom();
        }

        private reconnectToServer() {

            Model.Options.currentOptions.saveOptions();
            Model.Options.currentOptions.updateScreenResolution();

            if (this.connection.connectionState === Model.ConnectionState.Reconnecting) {
                return;
            }

            UI.OrientationController.resetZoom();

            this.shouldReconnectToServer = true;
            this.connection.disconnect();
        }


        private onConnected() {

            UI.AnimationController.showDesktop();

            $("#connectionStatus").text("Connected to " + Model.Options.currentOptions.server + " ");
            $("#staticUrl").text(window.location.href + "?" + Model.Options.QUERYKEY_CONNECT_TO + "=" + Model.Options.currentOptions.server);

            setTimeout(() => {
                UI.AnimationController.resetConnect();
            }, 1200);

            // $(".togglefullscreen").toggle(FullscreenController.fullscreenEnabled);
            $(".togglekvm").toggle(Model.Options.currentOptions.kvmControl  && !ViewController.touchDevice);
            $(".showKeyboard").toggle(Model.Options.currentOptions.kvmControl && Model.Options.currentOptions.kvmKeyboard && ViewController.touchDevice);

            if (!PointerLockController.pointerLockEnabled) {
                ViewController.Canvas.className = "noCursor";
            }
        }

        private onDisconnected() {

            if (this.shouldReconnectToServer) {
                this.connectUsingInput();
                this.shouldReconnectToServer = false;

                UI.AnimationController.showConnect();
            }
            else {
                FullscreenController.cancelFullscreen(false);
                UI.AnimationController.showStart(false);
            }

            $("#connectionStatus").text("Not connected");
        }


        /**
         * replace images with High-Res Images
         * 
         * @private
         */
        private setRetinaImages() {

            // Retina Images

            $("img").each(function () {
                let $img = $(this);
                let src = $img.data("src");
                // check if images exists

                if (src != null && src.length > 0) {

                    // ADDED: keep reference to current image
                    if (Utils.isRetinaDisplay) {

                        let ext = "." + src.substr((Math.max(0, src.lastIndexOf(".")) || Infinity) + 1);
                        let filesource = src.split(ext)[0];
                        let retinaImage = filesource + "@2x" + ext;

                        $.ajax({
                            url: retinaImage,
                            // error: function() { },
                            success: function () {
                                // file exists
                                // replace image with retina ready image 

                                if (!$img.hasClass("nowidth")) {
                                    $img.css("width", $img.get(0).clientWidth);
                                }
                                // currentImage.css("height", currentImage[0].height);

                                // currentImage refers to the correct image, and retinaImage refers to that image"s retina URL
                                $img.attr("src", retinaImage);
                            }
                        });
                    }
                    else {
                        $img.attr("src", src);
                    }
                }
            });
        }
    }
}