
namespace spacedesk.Model {
    
    export enum MouseButton {
        None = -1,
        Left = 0,
        Middle = 1,
        Right = 2,
    }
    export enum MouseState {
        Move,
        Down,
        Up,
        Scroll
    }
    export enum KeyState {
        Down,
        Up
    }
}