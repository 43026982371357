/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../../Model/Options.ts" />
/// <reference path="../../Model/Debug.ts" />
/// <reference path="../../Model/Render/RenderItemModel.ts" />
/// <reference path="./IRenderEngine.ts" />

namespace spacedesk.Controller.RenderEngine {

    export class CanvasRender implements IRenderEngine {

        public errorMessage: string;

        public targetCanvas: HTMLCanvasElement;
        // public desktopCanvas = document.getElementById("desktopCanvas") as HTMLCanvasElement;
        public resolution = new Model.Resolution(0, 0); 

        public context: CanvasRenderingContext2D;
        private compositeOver = true;

        constructor(targetCanvas: HTMLCanvasElement = null, compositeOver = true) {

            if (targetCanvas != null) {
                this.targetCanvas = targetCanvas;
            }
            else {
                this.targetCanvas = document.createElement("canvas");
            }

            this.compositeOver = compositeOver;
        }
        
        public draw(model: Model.Render.RenderItemModel) {

            Debug.time("CanvasDrawing");

            this.context.drawImage(model.image as HTMLImageElement, 0, 0, model.width, model.height, model.posX, model.posY, model.width, model.height);
            

            Debug.timeEnd("CanvasDrawing");
        }

        public onresize(resolution: Model.Resolution) {
            // Nothing to do here.
            this.resolution = resolution;
            this.targetCanvas.width = resolution.width;
            this.targetCanvas.height = resolution.height;
        }

        public init(): boolean {
            this.context = this.targetCanvas.getContext("2d", { willReadFrequently: true, alpha: false }) as CanvasRenderingContext2D;

            if (this.targetCanvas.hasAttribute("mozOpaque")) {
                (this.targetCanvas as any).mozOpaque = false;
            }
            
            if (this.compositeOver) {
                this.context.globalCompositeOperation = "destination-over";
            }
            return true;
        }

    }

}