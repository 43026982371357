
/// <reference path="../../../tools/typings/tsd.d.ts" />

namespace spacedesk.Controller.UI {

    export class LoaderController {

        public static registerLoader() {

            // makes sure the whole site is loaded
            $(window).on("load", function() {
                setTimeout(() => {
                    $("#loader").fadeOut();
                    // will first fade out the loading animation
                    $("#form-input").delay(350).fadeIn("slow");
                    $("#start footer").delay(350).fadeIn("slow");
                }, 300);
            });
        }
    }
}


