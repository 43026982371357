
/// <reference path="../../ProtocolDefinition.ts" />
/// <reference path="./GenericPayload.ts" />

namespace spacedesk.Model.Protocol.Packet.Payloads {

    export class IdentificationPayload extends GenericPayload {

        constructor() {

            super();

            let payload = new Uint8Array(ProtocolDefinition.payloadLength);

            payload[0] = 0x31;
            payload[1] = 0;
            payload[2] = 0x31;
            payload[3] = 0;
            payload[4] = 0x31;
            payload[5] = 0;
            payload[6] = 0x31;
            payload[7] = 0;
            payload[8] = 0x31;
            payload[9] = 0;

            payload[10] = 0x32;
            payload[11] = 0;
            payload[12] = 0x32;
            payload[13] = 0;
            payload[14] = 0x32;
            payload[15] = 0;
            payload[16] = 0x32;
            payload[17] = 0;
            payload[18] = 0x32;
            payload[19] = 0;

            let counter = 78;
            
            let name = spacedesk.Utils.browser.browser.name + " on " + spacedesk.Utils.browser.os.name;

            for (let i = 0; i < name.length; i++) {
                payload[counter] = name.charCodeAt(i);
                counter = counter + 2;
            }

            this.data = payload;
        }

    }

}