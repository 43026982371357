/// <reference path="../../../tools/typings/tsd.d.ts" />
/// <reference path="../../Model/ExtensionMethods.ts"/>
/// <reference path="../ViewController.ts" />
/// <reference path="KVMBaseController.ts" />
/// <reference path="../../Model/Debug.ts" />
/// <reference path="../../Model/InputEnums.ts" />
/// <reference path="../../Model/Point.ts" />
/// <reference path="../../Model/Rect.ts" />
/// <reference path="../../Model/Protocol/Packet/KVMPacket.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/GenericInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/KeyboardInputHeader.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/KVM/MouseInputHeader.ts" />
/// <reference path="../../Model/Protocol/ProtocolDefinition.ts" />
/// <reference path="../../Model/Protocol/Packet/Header/IdentificationHeader.ts" />


namespace spacedesk.Controller.KVM {

    import ProtocolDefinition = spacedesk.Model.Protocol.ProtocolDefinition;
    import Packet = spacedesk.Model.Protocol.Packet;
    import KVM = spacedesk.Model.Protocol.Packet.Header.KVM;
    import KeyState = spacedesk.Model.KeyState;
    import MouseButton = spacedesk.Model.MouseButton;
    import MouseState = spacedesk.Model.MouseState;
    import Point = spacedesk.Model.Point;

    export class TouchController extends Controller.KVM.KVMBaseController {

        // See https://msdn.microsoft.com/en-us/library/windows/desktop/ms646273(v=vs.85).aspx
        private static mouseMaxAbsolutePosition = 65535;
        private static key_backspace = 8;

        private tapDelayTimerId = -1;
        private tapDelay = 300;
        private touchDrag = false;


        protected addEventListener() {

            // Hack
            // Hidden input to force displaying Keyboard on mobile devices
            // Catch Events on input, ignore input and send data
            /*
            if (Model.Options.currentOptions.kvmKeyboard) {
                
                document.getElementById("keyboard").addEventListener("keydown", this.fakeKeyboardKeyDownEvent.bind(this), false);
                document.getElementById("keyboard").addEventListener("keyup", this.fakeKeyboardKeyUpEvent.bind(this), false);

                // if you paste text via your mobile keyboard no keydown event is fired. But an input Event is fired, so we catch this one.
                $("#keyboard").on("input propertychange", this.fakeKeyboardChangeEvent.bind(this));

                // Keyboard fallback
                window.addEventListener("keydown", this.keyDownEvent.bind(this), false);
                window.addEventListener("keyup", this.keyUpEvent.bind(this), false);
            }
            */
            (ViewController.Canvas).addEventListener("touchstart", this.touchStartEvent.bind(this));
            (ViewController.Canvas).addEventListener("touchmove", this.touchMoveEvent.bind(this));
            (ViewController.Canvas).addEventListener("touchend", this.touchEndEvent.bind(this));
             
        }

        protected removeEventListener() {
            // Keyboard
            // window.removeEventListener("keydown", this.keyDownEvent);
            // window.removeEventListener("keyup", this.keyUpEvent);

            // document.getElementById("keyboard").removeEventListener("keydown", this.fakeKeyboardKeyDownEvent);
            // document.getElementById("keyboard").removeEventListener("keyup", this.fakeKeyboardKeyUpEvent);

            // $("#keyboard").off("input propertychange", this.fakeKeyboardChangeEvent.bind);

            $(ViewController.Canvas).swipe("destroy");
        }

        private touchMoveEvent(event: TouchEvent, target: Element) {
            this.sendTouch(spacedesk.Model.Protocol.TouchState.TouchSateNone, this.getPointerPosition(event));
        }

        private touchStartEvent(event: TouchEvent, target: Element) {
            this.sendTouch(spacedesk.Model.Protocol.TouchState.TouchStateDown, this.getPointerPosition(event));
        }

        private touchEndEvent(event: TouchEvent, target: Element) {
            this.sendTouch(spacedesk.Model.Protocol.TouchState.TouchStateUp, this.getPointerPosition(event));
        }

        private getPointerPosition(event: TouchEvent): Point {
            return new Point(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
        }

    }

}