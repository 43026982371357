/// <reference path="../../../ProtocolDefinition.ts" />
/// <reference path="../../../../Resolution.ts" />
/// <reference path="../../../../Options.ts" />
/// <reference path="../../../../Rect.ts" />
/// <reference path="../GenericHeader.ts" />

namespace spacedesk.Model.Protocol.Packet.Header.KVM {

    export abstract class GenericInputHeader  {

        public abstract GetBytes(): Uint8Array;

    }

}