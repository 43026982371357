/// <reference path="../ProtocolDefinition.ts" />
/// <reference path="../Packet/GenericRequestPacket.ts"/>


namespace spacedesk.Model.Protocol.Packet {

    export class FlowControlAckPacket extends SpacedeskPacket {
        
        private data: Uint8Array;

        public GetBufferData(): Uint8Array {

            const POSITION_TYPE = 0;
            const POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER = 4;

            this.data = new Uint8Array(ProtocolDefinition.headerLength);
            
            this.data.assignInteger32(POSITION_TYPE, ProtocolHeaderType.FlowControlAck);
            this.data.assignInteger32(POSITION_COUNT_BYTE_DATA_FOLLOWING_HEADER, 0);

            return this.data;            
        }

    }

}